import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';

import { ProductsComponent } from './products.component';

import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { SystemService } from 'src/app/services/system.service';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { AdminHeaderModule } from '../header/header.module';
import { SidebarModule } from 'primeng/sidebar';
import { HttpClient } from '@angular/common/http';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { InputTextModule } from 'primeng/inputtext';
import { ProductDetailsModule } from 'src/app/sub/details/details.module';
import { CheckboxModule } from 'primeng/checkbox';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardOrders  {

	canActivate(route: ActivatedRouteSnapshot) {
		let success = false;
		let user = sessionStorage.getItem('uid');
		console.log("User", user);
		if (user == 'mQTD3KZTH2dbu6D7I3wFoCxWIg73') { success = true; }
		return success;
	}

}

const routes = [
	{ path: 'admin/products', component: ProductsComponent, canActivate: [AuthGuardOrders] }
]

@NgModule({
	declarations: [
		ProductsComponent,
	],
	imports: [
		ConfirmDialogModule,
		AdminHeaderModule,
		CommonModule,
		FormsModule,
		TooltipModule,
		ButtonModule,
		TableModule,
		ProgressBarModule,
		DropdownModule,
		AdminHeaderModule,
		SidebarModule,
		OverlayPanelModule,
		InputTextModule,
		CheckboxModule,
		ProductDetailsModule,
		RouterModule.forChild(routes),
	],
	providers: [SystemService, HttpClient, ConfirmationService],
	exports: [ProductsComponent]
})
export class AdminProductsModule { }
