import { NgModule } from '@angular/core';

import { HeaderComponent } from './header.component';
import { AdminNavModule } from '../admin/nav/nav.module';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { AdminService } from '../admin/admin/admin.service';
import { BadgeModule } from 'primeng/badge';
import { SystemService } from '../services/system.service';
import {MenuModule} from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { CartService } from '../pages/cart/cart.service';
@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    AdminNavModule,
    CommonModule,
    TooltipModule,
    BadgeModule,
    MenuModule,
    ButtonModule,
  ],
  providers: [AdminService, SystemService, CartService],
  exports: [HeaderComponent]
})
export class HeaderModule { }
