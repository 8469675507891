import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import * as pistols from 'src/assets/json/pistols.json';
import * as colors from 'src/assets/json/colors.json';
import * as lights from 'src/assets/json/lights.json';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-holsters',
	templateUrl: './holsters.component.html',
})
export class HolstersComponent implements OnInit {
	holster: any = {
		type: "el taco",
		hand: "Right",
		style: "IWB",
		notes: "",
		shield: false,
		layers: 1,
		layer1: "Blizzard White",
		buckle: "Basic Clip",
		image: null,
		reddot: false,
		pistols: [{ make: null, model: null, qty: 1 }],
		price: 49.95
	}

	images: any[] = [
		{ image: 'assets/images/art/1.png', description: "1 sided print. Classic. Vintage." },
		{ image: 'assets/images/art/2.png', description: "2 sided print, full bleed. Show your support for 9/11!" },
		{ image: 'assets/images/art/3.png', description: "2 sided print, full bleed. Italian flag with skull in the middle." },
		{ image: 'assets/images/art/4.png', description: "1 sided print. She's gangsta! Classic. Vintage." },
		{ image: 'assets/images/art/5.png', description: "2 sided print, full bleed. Modern camo in blue and black." },
		{ image: 'assets/images/art/6.png', description: "2 sided print, full bleed. Modern camo in yellow and black." },
		{ image: 'assets/images/art/7.png', description: "2 sided print, full bleed. Modern camo in blue and black." },
		{ image: 'assets/images/art/8.png', description: "2 sided print, full bleed. Skull in blue and orange against black background." },
		{ image: 'assets/images/art/9.png', description: "2 sided print, full bleed. Warrior in lake surrounded by flowers." },
		{ image: 'assets/images/art/10.png', description: "2 sided print, full bleed. Warriors in field. Blue and black." },
	]

	samples: any[] = [
		{ small: "assets/images/holsters/holster_1.png", big: "assets/images/holsters/holster1_big.png" },
		{ small: "assets/images/holsters/holster_3.png", big: "assets/images/holsters/holster3_big.png" },
		{ small: "assets/images/holsters/holster_2.png", big: "assets/images/holsters/holster_2.png" },
		{ small: "assets/images/holsters/holster6_big.png", big: "assets/images/holsters/holster6_big.png" },
		{ small: "assets/images/holsters/holster4_big.png", big: "assets/images/holsters/holster4_big.png" },
		{ small: "assets/images/holsters/holster5_big.png", big: "assets/images/holsters/holster5_big.png" },
	]
	selectedSample: any;

	manufacturer: any[] = (pistols as any).default['pistols'];
	colors: any[] = (colors as any).default['colors'];
	lights: any[] = (lights as any).default['lights'];
	search: string = "";
	searchResults: any[] = [];

	constructor(
		private messageService: MessageService,
		private systemService: SystemService,
		private http: HttpClient
	) {

	}

	ngOnInit(): void {
		this.selectedSample = this.samples[0];

		console.log(this.manufacturer);
		let tot1 = 0;
		let tot2 = 0;
		this.manufacturer.forEach(man => {
			tot1++;
			man.models.forEach(make => {
				tot2++;

			});
		})
		console.log(tot1);
		console.log(tot2);
	}

	addPistol() {
		this.holster.pistols.push({ make: {}, model: "", qty: 1 })
	}

	getTotal() {
		let qty = 0;
		this.holster.pistols.forEach(elem => {
			qty += elem.qty;
		})
		return (this.holster.price * qty).toFixed(2);
	}

	addToCart() {
		console.log(this.holster);
		if ((!this.holster.pistols[0].make) && (!this.holster.pistols[0].model)) {
			this.messageService.add({ key: "general", severity: 'error', summary: 'Cart', detail: 'Please select a pistol make and model.' });
			return false;
		}
		let cart = [];
		if (localStorage.getItem('cart')) { cart = JSON.parse(localStorage.getItem('cart')) };
		this.holster.pistols.forEach(pistol => {
			// let item = pistol;
			let item = {
				type: this.holster.type,
				hand: this.holster.hand,
				style: this.holster.style,
				shield: this.holster.shield,
				layer1: this.holster.layer1,
				buckle: this.holster.buckle,
				reddot: this.holster.reddot,
				price: this.holster.price,
				notes: this.holster.notes,
				make: pistol.make.label,
				model: pistol.model,
				light: pistol.light ? pistol.light : "None",
				qty: pistol.qty
			}
			cart.push(item);

			let msg = "*Item added to cart*\n";
			msg += "**El Taco - " + pistol.make.label + ": " + pistol.model + "**";
			this.systemService.notifyDiscord(msg);
		});
		console.log("Cart", cart);
		this.systemService.saveCartToStorage(cart);

		this.systemService.cartUpdated.next("");
		this.messageService.add({ key: "create", severity: 'success', summary: 'Cart', detail: 'Holster added!' });
	}

	removePistol(index) {
		this.holster.pistols.splice(index, 1);
	}

	findImage() {
		let payload = {
			query: this.search
		}
		this.http.post('https://us-central1-holsters-fc1c6.cloudfunctions.net/searchShutterstock', 
		payload, 
		{ headers: { "Authorization": "bearer $(gcloud auth print-identity-token)" } }).subscribe(res => {
			this.searchResults = res['data'];
			console.log(this.searchResults);
		});
	}
}
