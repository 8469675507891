import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SortIcon } from 'primeng/table';
import { AdminService } from 'src/app/admin/admin/admin.service';
import { ApplicationsService } from 'src/app/admin/application/application.service';

@Component({
	selector: 'app-application',
	templateUrl: './application.component.html',
})
export class ApplicationComponent implements OnInit, OnDestroy {
	application: any = {
		name: "",
		address1: "",
		address2: "",
		city: "",
		zipcode: "",
		phone: "",
		email: "",
		dob: "",

	}
	options: any[] = [{ name: "Yes" }, { name: "No" }]
	admin: any = { banner: "", hiring: "" };

	myForm: UntypedFormGroup;

	constructor(
		private applicationService: ApplicationsService,
		private messageService: MessageService,
		private adminService: AdminService
	) {

	}

	ngOnInit(): void {

		this.myForm = new UntypedFormGroup({
			name: new UntypedFormControl('', Validators.required),
			dob: new UntypedFormControl('', Validators.required),
			email: new UntypedFormControl('', Validators.required),
			address1: new UntypedFormControl('', Validators.required),
			address2: new UntypedFormControl(''),
			city: new UntypedFormControl('', Validators.required),
			zipcode: new UntypedFormControl('', Validators.required),
			phone: new UntypedFormControl('', Validators.required),
			position: new UntypedFormControl('', Validators.required),
			citizen: new UntypedFormControl('', Validators.required),
			age: new UntypedFormControl('', Validators.required),
			crime: new UntypedFormControl('', Validators.required),
			startdte: new UntypedFormControl('', Validators.required),
			highschool: new UntypedFormControl('', Validators.required),
			college: new UntypedFormControl(''),
			military: new UntypedFormControl(''),
			reference: new UntypedFormControl('', Validators.required),
			c1: new UntypedFormControl(''),
			c1dte: new UntypedFormControl(''),
			p1: new UntypedFormControl(''),
			p1startdte: new UntypedFormControl(''),
			p1enddte: new UntypedFormControl(''),
			p2: new UntypedFormControl(''),
			p2startdte: new UntypedFormControl(''),
			p2enddte: new UntypedFormControl('')
		});

		this.adminService.getAdmin().subscribe(res => {
			console.log(res);
			this.admin = res;
		})
	}

	ngOnDestroy(): void {

	}

	postApplication(form: UntypedFormGroup) {
		if (!form.valid) {
			this.messageService.add({ severity: 'error', summary: 'System', detail: 'Please fill out all required fields' });

			return false;
		}

		let payload = {
			name: form.value.name,
			dob: new Date(form.value.dob).toDateString(),
			email: form.value.email,
			address1: form.value.address1,
			address2: form.value.address2,
			city: form.value.city,
			zipcode: form.value.zipcode,
			phone: form.value.phone,
			dtetme: new Date().toDateString(),
			position: form.value.position,
			citizen: form.value.citizen,
			age: form.value.age,
			crime: form.value.crime,
			highschool: form.value.highschool,
			college: form.value.college,
			military: form.value.military,
			reference: form.value.reference,
			startdte: new Date(form.value.startdte).toDateString(),
			c1: form.value.c1,
			c1dte: new Date(form.value.c1dte).toDateString(),
			p1: form.value.p1,
			p1startdte: new Date(form.value.p1startdte).toDateString(),
			p1enddte: new Date(form.value.p1enddte).toDateString(),
			p2: form.value.p2,
			p2startdte: new Date(form.value.p2startdte).toDateString(),
			p2enddte: new Date(form.value.p2enddte).toDateString(),
		}
		this.applicationService.postApplication(payload).then(res => {
			console.log(res);
			this.messageService.add({ severity: 'success', summary: 'System', detail: "Application submitted! We'll be in touch SortIcon." });

		})
	}
}
