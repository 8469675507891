import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrdersComponent } from './orders.component';

import { AdminService } from 'src/app/admin/admin/admin.service';
import { TooltipModule } from 'primeng/tooltip';
import { OrdersService } from './orders.service';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProductDetailsModule } from 'src/app/sub/details/details.module';
import { ShippingModule } from 'src/app/sub/shipping/shipping.module';
import { InputTextModule } from 'primeng/inputtext';

const routes: Routes = [
	{ path: 'orders', component: OrdersComponent }
]

@NgModule({
	declarations: [
		OrdersComponent,
	],
	imports: [
		CommonModule,
		TooltipModule,
		TableModule,
		ButtonModule,
		ProgressBarModule,
		ProductDetailsModule,
		ShippingModule,
		InputTextModule,
		RouterModule.forChild(routes),
	],
	providers: [OrdersService],
	exports: [OrdersComponent]
})
export class OrdersModule { }
