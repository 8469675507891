<div class="p-grid" style="margin: 20px;">
	<div class="p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12">
		<h3>Website</h3>
		<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>

		<div class='grid' *ngIf="!loading">
			<div class='col-12'>
				<label class='sidebar_label'>Homepage Top Banner Text</label>
				<textarea pInputTextarea [(ngModel)]="admin.banner" class='form-control'></textarea>
			</div>

			<div class='col-12'>
				<label class='sidebar_label'>Hiring Message</label>
				<textarea pInputTextarea [(ngModel)]="admin.hiring" class='form-control'></textarea>
			</div>

			<div class='col-12'>
				<label class='sidebar_label'>Are you currently hiring?</label>
				<p-selectButton [options]="hiringOptions" [(ngModel)]="admin.hiringToggle" optionLabel="name"></p-selectButton>
			</div>
			<div class='col-12'>
				<label class='sidebar_label'>Restuarant Phone #</label>
				<input type="text" pInputText [(ngModel)]="admin.phone" class='form-control'>
			</div>

			<div class='col-12'>
				<label class='sidebar_label'>Restuarant Email Address</label>
				<input type="text" pInputText [(ngModel)]="admin.email" class='form-control'>
			</div>

			<div class='col-12'>
				<button (click)="saveAdmin()" class='p-button p-button-success'>Save</button>
			</div>
		</div>
	</div>
</div>