import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { SystemService } from 'src/app/services/system.service';

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit {
	@Input() user: any;
	@Input() cart: boolean = false;
	@Input() address: any;
	@Input() address_components: any;
	@Output() callback = new EventEmitter();

	@ViewChild("placesRef") placesRef: GooglePlaceDirective;
	editing: boolean = false;
	loading: boolean = false;

	options: {
		componentRestrictions: { country: "us" },
	};
	constructor(
		private systemService: SystemService
	) {

	}

	ngOnInit(): void {

		if (!this.address_components) {
			this.address_components = {
				address1: "",
				address2: "",
				city: "",
				state: "",
				postal: ""
			}
		}
	}

	saveAddress() {
		this.callback.emit({ address: this.address, address_components: this.address_components });
	}

	public handleAddressChange(address: any) {
		console.log(address);
		this.address = address.formatted_address;
		this.fillInAddress()
	}

	fillInAddress() {
		// Get the place details from the autocomplete object.
		const place = this.placesRef.place;
		let address1 = "";
		let postcode = "";

		let payload = {
			address1: "",
			address2: "",
			city: "",
			state: "",
			postal: "",
			country: ""
		}
		for (const component of place.address_components) {
			// @ts-ignore remove once typings fixed
			const componentType = component.types[0];

			switch (componentType) {
				case "street_number": {
					address1 = `${component.long_name} ${address1}`;
					break;
				}

				case "route": {
					address1 += component.short_name;
					break;
				}

				case "postal_code": {
					postcode = `${component.long_name}${postcode}`;
					break;
				}

				case "postal_code_suffix": {
					postcode = `${postcode}-${component.long_name}`;
					break;
				}

				case "locality":
					payload.city = component.long_name;
					console.log(component.long_name);
					break;

				case "administrative_area_level_1": {
					payload.state = component.short_name;
					break;
				}

				case "country":
					payload.country = component.long_name;
					break;
			}
		}
		payload.address1 = address1;
		payload.postal = postcode;
		console.log(payload);
		this.address_components = payload;
	}

}
