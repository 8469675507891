<app-admin-header></app-admin-header>
<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Orders</h2>
				</div>
			</div>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
				<p-table #dt *ngIf="!loading" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
					responsiveLayout="scroll"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
					[rowsPerPageOptions]="[10,25,50]" [value]="orders" dataKey="id" responsiveLayout="scroll">
					<ng-template pTemplate="header">
						<tr>
							<th scope='col' style="width: 3rem"></th>
							<th scope='col'></th>
							<th scope='col' pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
								<p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
							</th>
							<th scope='col' pSortableColumn="email">Email <p-sortIcon field="email"></p-sortIcon>
								<p-columnFilter type="text" field="email" display="menu"></p-columnFilter>
							</th>
							<th scope='col' pSortableColumn="dtetme">Date <p-sortIcon field="dtetme"></p-sortIcon>
								<p-columnFilter type="date" field="dtetme" display="menu"></p-columnFilter>
							</th>
							<th scope='col' pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon>
								<p-columnFilter field="status" matchMode="equals" display="menu">
									<ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-dropdown [ngModel]="value" [options]="states" optionValue="label"
											optionLabel="label" (onChange)="filter($event.value)" placeholder="Any">
											<ng-template let-option pTemplate="item">
												<span>{{option.label}}</span>
											</ng-template>
										</p-dropdown>
									</ng-template>
								</p-columnFilter>
							</th>
							<th scope='col' pSortableColumn="amount">Total <p-sortIcon field="amount"></p-sortIcon>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-order let-ri="rowIndex" let-expanded="expanded">
						<tr>
							<td>
								<button type="button" pButton pRipple [pRowToggler]="order"
									class="p-button-text p-button-rounded p-button-plain"
									[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
							</td>
							<td>
								<span *ngIf="order.status !== 'Shipped'" class="p-buttonset"
									style="white-space: nowrap;">
									<button pButton pTooltip="Delete" (click)='deleteOrder(order)'><i
											class='pi pi-trash'></i></button>
									<button pButton pTooltip="Generate shipping label"
										(click)='clearRates(order); shipping.toggle($event); getRates();'><i
											class='pi pi-shopping-cart'></i></button>
								</span>
							</td>
							<td>
								{{order.name}}
							</td>
							<td>
								{{order.email}}
							</td>
							<td>{{order.dtetme}}</td>
							<td>
								<p-dropdown *ngIf="order.status !== 'Shipped'" [options]="states"
									[autoDisplayFirst]="false" [(ngModel)]="order.status" optionValue="label"
									optionLabel="label" appendTo="body"></p-dropdown>
								<span *ngIf="order.status == 'Shipped'">Shipped</span>
							</td>
							<td>${{(order.amount) | number : '.2-2'}}</td>

						</tr>
					</ng-template>
					<ng-template pTemplate="rowexpansion" let-details>
						<tr>
							<td colspan="8">
								<div *ngIf="details.label_url">
									<a [href]="details.label_url" target="_blank">Print Shipping Label</a> | <a
										[href]="details.label_url" target="_blank">Print Packing Label</a>
								</div>
								<app-product-details *ngFor="let item of details.cart"
									[details]="item"></app-product-details>
							</td>
						</tr>
					</ng-template>

					<ng-template *ngIf="!orders.length" pTemplate="summary">
						No orders found.
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</section>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-overlayPanel #shipping appendTo="body">
	<ng-template pTemplate>
		<!-- <div class="row">
			<div class="col-xs-12">
				<button pButton (click)="getRates()">Get Rates</button>
			</div>
		</div> -->

		<p-progressBar *ngIf="loadingShipping" mode="indeterminate"></p-progressBar>
		<p-table #dt *ngIf="rates && rates.length && !loadingShipping" responsiveLayout="scroll" [value]="rates">
			<ng-template pTemplate="header">
				<tr>
					<th scope='col'>Carrier</th>
					<th scope='col'>Rate</th>
					<th scope='col'>Estimated</th>
					<th scope='col'></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rate let-ri="rowIndex" let-expanded="expanded">
				<tr>
					<td>{{rate.provider}}</td>
					<td>${{rate.amount}}</td>
					<td>{{rate.estimated_days}} days</td>
					<td>
						<span class="p-buttonset" style="white-space: nowrap;">
							<button pButton pTooltip="Print Label" [loading]="loadingLabel"
								(click)='createLabel(rate)'><i class='pi pi-print'></i></button>
						</span>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
</p-overlayPanel>