import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { SpecialsModule } from './pages/specials/specials.module';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from 'src/environments/environment';

import { AdminLoginModule } from './login/login.module';
import { AuthService } from './services/auth.service';
import { HomeModule } from './pages/home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AdminModule } from './admin/admin/admin.module';
import { ToastModule } from 'primeng/toast';
import { AdminApplicationsModule } from './admin/application/application.module';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationModule } from './pages/application/application.module';
import { ContactModule } from './pages/contact/contact.module';
import { HolstersModule } from './pages/holsters/holsters.module';
import { ButtonModule } from 'primeng/button';
import { CartModule } from './pages/cart/cart.module';
import { ProfileModule } from './pages/profile/profile.module';
import { OrdersModule } from './pages/orders/orders.module';
import { NakedModule } from './pages/naked/naked.module';
import { AdminUsersModule } from './admin/users/users.module';
import { AdminOrdersModule } from './admin/orders/orders.module';
import { AboutModule } from './pages/about/about.module';
import { PointsModule } from './pages/points/points.module';
import { Grip1911Module } from './pages/1911/1911.module';
import { PrivacyModule } from './pages/privacy/privacy.module';
import { TermsModule } from './pages/terms/terms.module';
import { GearModule } from './pages/gear/gear.module';
import { AuthModule } from './pages/auth/auth.module';
import { BraceletModule } from './pages/bracelets/bracelets.module';
import { AdminProductsModule } from './admin/products/products.module';

import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DashboardModule } from './admin/dashboard/dashboard.module';
import { AdminAmazonModule } from './admin/amazon/amazon.module';
import { StowawayModule } from './pages/stowaway/stowaway.module';
import { AdminPistolsModule } from './admin/pistols/pistols.module';
import { OrderLookupModule } from './pages/orders_lookup/orders_lookup.module';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const clonedRequest = req.clone({ headers: req.headers.set('production', environment.production ? "true" : "false") });

    // Pass the cloned request instead of the original request to the next handle
    console.log(clonedRequest);
    return next.handle(clonedRequest);
  }
}

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
]
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ButtonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    SpecialsModule,
    ApplicationModule,
    AdminModule,
    AdminLoginModule,
    ContactModule,
    ProfileModule,
    AdminApplicationsModule,
    HomeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    ToastModule,
    HolstersModule,
    CartModule,
    OrdersModule,
    NakedModule,
    AboutModule,
    PointsModule,
    AdminUsersModule,
    AdminOrdersModule,
    AdminProductsModule,
    Grip1911Module,
    PrivacyModule,
    TermsModule,
    AuthModule,
    BraceletModule,
    GearModule,
    DashboardModule,
    AdminAmazonModule,
    StowawayModule,
    AdminPistolsModule,
    OrderLookupModule,
    RouterModule.forChild(routes)

  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
