<footer>
	<div class="footer-bottom">
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-sm-12">
					<div class="copyright sm-t-center">
						<p style="line-height: 24px;">Copyright 2022 
							<a href="/home" text="home"><span>holsters.io LLC</span></a> | 
							<a href="/privacy" text="privacy policy"><span>Privacy Policy</span></a>
						</p>
						<p style="line-height: 24px;">We made this website in house. We're pretty proud of it. <a href='/contact' text='contact us' style="color: #878244;">Contact Us</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>