import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
	name: string = "";
	subject: string = "";
	email: string = "";
	message: string = "";

	constructor(
		private systemService: SystemService,
		private messageService: MessageService
	) {

	}

	ngOnInit(): void {
		this.getData();

	}

	getData() {

	}

	Send() {
		if (!this.name) { this.messageService.add({ key: "general", severity: 'error', summary: 'Contact Form', detail: 'Name field required' }); return false; }
		if (!this.email) { this.messageService.add({ key: "general", severity: 'error', summary: 'Contact Form', detail: 'Email field required' }); return false; }
		if (!this.subject) { this.messageService.add({ key: "general", severity: 'error', summary: 'Contact Form', detail: 'Subject field required' }); return false; }
		if (!this.message) { this.messageService.add({ key: "general", severity: 'error', summary: 'Contact Form', detail: 'Message field required' }); return false; }

		let message = "Name: " + this.name + "<br>Email: " + this.email + "<br>Subject: " + this.subject + "<br>Message: " + this.message
		let payload = {
			to: "admin@holsters.io",
			subject: "Contact Form",
			text: message,
			html: message
		}
		this.systemService.sendEmail(payload).subscribe(res => {
			console.log(res);
			if (res['status']) {
				this.messageService.add({ key: "general", severity: 'success', summary: 'Contact Form', detail: 'Message Sent!' });

			}
		})
		this.Clear();
	}

	Clear() {
		this.name = "";
		this.subject = "";
		this.email = "";
		this.message = "";
	}
}
