import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrderLookupComponent } from './orders_lookup.component';

import { AdminService } from 'src/app/admin/admin/admin.service';
import { TooltipModule } from 'primeng/tooltip';
import { OrderLookupService } from './orders_lookup.service';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProductDetailsModule } from 'src/app/sub/details/details.module';
import { ShippingModule } from 'src/app/sub/shipping/shipping.module';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { OrdersModule } from '../orders/orders.module';

const routes: Routes = [
	{ path: 'order-lookup', component: OrderLookupComponent }
]

@NgModule({
	declarations: [
		OrderLookupComponent,
	],
	imports: [
		FormsModule,
		CommonModule,
		TooltipModule,
		TableModule,
		ButtonModule,
		ProgressBarModule,
		ProductDetailsModule,
		ShippingModule,
		InputTextModule,
		OrdersModule,
		RouterModule.forChild(routes),
	],
	providers: [OrderLookupService],
	exports: [OrderLookupComponent]
})
export class OrderLookupModule { }
