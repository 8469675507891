import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AdminService } from 'src/app/admin/admin/admin.service';
import { SystemService } from 'src/app/services/system.service';
@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
	user: any = {
		email: "",
		password: "",
		address: null,
		name: "",
		company: ""
	}

	loggedIn: boolean = false;
	loadingProfile: boolean = true;

	newsletter: any = {
		products: false,
		price: false,
		holiday: false
	}

	tmpUser: any = {
		company: "",
		name: ""
	}

	constructor(
		private adminService: AdminService,
		private messageService: MessageService,
		private systemService: SystemService,
		private router: Router
	) {

	}

	ngOnInit(): void {
		this.loggedIn = this.systemService.funcCheckAuth();
		this.getProfile();
		this.getNewsletter();
	}

	getProfile() {
		if (sessionStorage.getItem('uid')) {
			this.systemService.getProfileService().subscribe(res => {
				console.log(res);
				if (res) {
					this.user.name = res['name'] ? res['name'] : "";
					this.tmpUser.name = this.user.name ? this.user.name : "";

					this.user.company = res['company'] ? res['company'] : "";
					this.tmpUser.company = this.user.company ? this.user.company : "";

					this.user.email = res['email'];

					this.user.billing_address = res['billing_address'] ? res['billing_address'] : null;
					this.user.billing_address_components = res['billing_address_components'] ? res['billing_address_components'] : null;

					this.user.shipping_address = res['shipping_address'] ? res['shipping_address'] : null;
					this.user.shipping_address_components = res['shipping_address_components'] ? res['shipping_address_components'] : null;
				}
				this.loadingProfile = false;
			})
		}
	}

	getNewsletter() {
		this.systemService.getNewsletter().subscribe(res => {
			this.newsletter = res ? res : {
				products: false,
				price: false,
				holiday: false
			};
		})
	}

	saveBilling(data) {
		this.systemService.putProfile({ billing_address: data.address, billing_address_components: data.address_components });
		this.messageService.add({ key: "general", severity: 'success', summary: 'Profile', detail: 'Billing information saved' });

	}

	saveShipping(data) {
		this.systemService.putProfile({ shipping_address: data.address, shipping_address_components: data.address_components });
		this.messageService.add({ key: "general", severity: 'success', summary: 'Profile', detail: 'Shipping information saved' });
	}

	updateName() {
		this.systemService.putProfile({ name: this.user.name, company: this.user.company });
	}

	saveNewsletter() {
		console.log(this.newsletter);
		this.systemService.putNewsletter(this.newsletter).then(res => {
			console.log(res);
			this.getNewsletter();
		this.messageService.add({ key: "general", severity: 'success', summary: 'Profile', detail: 'Communications preferences saved' });

		})
	}

	logout() {
		sessionStorage.clear();
		localStorage.clear();
		this.systemService.loginUpdated.next(false);
		this.systemService.cartUpdated.next(null);
		this.router.navigate(['/home']);
	}
}
