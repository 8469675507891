import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterModule, Routes } from '@angular/router';

import { ProfileComponent } from './profile.component';

import { AdminService } from 'src/app/admin/admin/admin.service';
import { TooltipModule } from 'primeng/tooltip';
import { OrdersModule } from '../orders/orders.module';
import { AccordionModule } from 'primeng/accordion';
import { SystemService } from 'src/app/services/system.service';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { AddressModule } from 'src/app/sub/address/address.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessageService } from 'primeng/api';


@Injectable({
	providedIn: 'root'
})
export class authGuardProfile implements CanActivate {

	constructor() { }

	canActivate(route: ActivatedRouteSnapshot) {
		let success = true;
		let user = sessionStorage.getItem('uid');
		if (user) {
			console.log("User", user);
			if (!user) { success = false; }
			return success;
		} else { return false; }
	}

}
const routes: Routes = [
	{ path: 'account', component: ProfileComponent, canActivate: [authGuardProfile] }
]

@NgModule({
	declarations: [
		ProfileComponent,
	],
	imports: [
		AddressModule,
		CheckboxModule,
		FormsModule,
		CommonModule,
		TooltipModule,
		OrdersModule,
		AccordionModule,
		InputTextModule,
		ButtonModule,
		ProgressBarModule,
		RouterModule.forChild(routes),
	],
	providers: [AdminService, SystemService, MessageService],
	exports: [ProfileComponent]
})
export class ProfileModule { }
