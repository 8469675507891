<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 col-md-6 col-sm-12 col-xs-12">
				<div class="products-photo">
					<!-- Tab panes -->
					<div class="tab-content">
						<div role="tabpanel" class="tab-pane active" id="img1">
							<img [src]="selectedSample.big" alt="" />
						</div>
					</div>
					<!-- Nav tabs -->
					<ul class="nav nav-tabs" role="tablist">
						<li role="presentation" class="active" *ngFor="let img of samples">
							<a (click)='selectedSample = img' role="tab" data-bs-toggle="tab"><img [src]="img.small"
									alt="" /></a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-7 col-md-6 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Customize El Taco</h2>
					<p-accordion>
						<p-accordionTab header="Pistol" [selected]="true">
							<div class="row">
								<div class='col-lg-12'>
									<p-table [value]="holster.pistols" responsiveLayout="scroll">
										<ng-template pTemplate="header">
											<tr>
												<th>Make</th>
												<th>Model</th>
												<th>Light / Laser</th>
												<th>QTY</th>
												<th><button (click)="addPistol()"
														pTooltip="Add another holster with the same configuration"
														class='btn btn-info'><i class='pi pi-plus'></i></button></th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-pistol let-ri="rowIndex">
											<tr>
												<td>
													<p-dropdown [options]="manufacturer" [autoDisplayFirst]="false"
														[(ngModel)]="pistol.make" optionLabel="label" [showClear]="true"
														filter="true" appendTo="body"></p-dropdown>
												</td>
												<td>
													<p-dropdown *ngIf="pistol.make" [options]="pistol.make.models"
														[autoDisplayFirst]="false" [(ngModel)]="pistol.model"
														optionValue="label" optionLabel="label" [showClear]="true"
														filter="true" appendTo="body"></p-dropdown>
												</td>
												<td>
													<p-dropdown [options]="lights" [autoDisplayFirst]="false"
														[(ngModel)]="pistol.light" optionValue="label"
														optionLabel="label" [showClear]="true" filter="true"
														appendTo="body"></p-dropdown>
												</td>
												<td>
													<p-inputNumber [(ngModel)]="pistol.qty" [min]="1"
														class='qty'></p-inputNumber>
												</td>
												<td><button *ngIf="holster.pistols.length > 1"
														(click)="removePistol(ri)" class='btn btn-danger'
														pTooltip="Remove"><i class='pi pi-trash'></i></button></td>
											</tr>
										</ng-template>

									</p-table>
								</div>
							</div>
							
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<p style="text-align: center; color: red;"><b>Add a Little Friend for the same pistol and take $10 off in the cart</b></p>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-12">
									<p>Want multiple holsters with the same design, but for different pistols? Click
										that + button to add more.</p>
								</div>
							</div>
							
							<div class="row">
								<div class="col-lg-12">
									<label>Additional notes such as light placement or barrel opening. We'll do our best to accomodate all requests. We'll email you if we have questions.</label>
									<textarea pInputTextarea [(ngModel)]="holster.notes" class="form-control"></textarea>
								</div>
							</div>
						</p-accordionTab>
						<p-accordionTab [header]="'Hand - ' + holster.hand">
							<div class="row">
								<div class="col-lg-6">
									<p-radioButton name="hand" value="Left" [(ngModel)]="holster.hand"></p-radioButton>
									Left
								</div>
								<div class="col-lg-6">
									<p-radioButton name="hand" value="Right" [(ngModel)]="holster.hand"></p-radioButton>
									Right
								</div>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">Which hand do you shoot with? This is usually your writing hand.
								</div>
							</div>
						</p-accordionTab>
						<p-accordionTab [header]="'Style - ' + holster.style">
							<div class="row">
								<div class="col-lg-6">
									<p-radioButton name="style" value="IWB" [(ngModel)]="holster.style"></p-radioButton>
									IWB
								</div>
								<div class="col-lg-6">
									<p-radioButton name="style" value="OWB" [(ngModel)]="holster.style"></p-radioButton>
									OWB
								</div>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12" *ngIf="holster.style == 'IWB'">IWB - Inside waistband means you
									want to wear your holster inside your wasitband.</div>
								<div class="col-lg-12" *ngIf="holster.style == 'OWB'">OWB - Outside waistband means you
									want to wear your holster outside your waistband. This is really a matter of
									personal preference. </div>
							</div>
						</p-accordionTab>

						<p-accordionTab [header]="'RMR cut? ' + (holster.reddot ? 'Yes' : 'No')">
							<div class="row">
								<div class="col-lg-6">
									<p-checkbox [binary]="true" [(ngModel)]="holster.reddot"></p-checkbox>
									{{holster.reddot ? 'Yes' : 'No'}}
								</div>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<p>An RMR or Red Dot cut is a cut at the top of the holster (top side of the pistol)
										that allows for the mounting of optics such as red dots. </p>
								</div>
							</div>
						</p-accordionTab>
						<p-accordionTab [header]="'Sweatshield? ' + (holster.shield ? 'Yes' : 'No')">
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<p-checkbox [(ngModel)]="holster.shield" [binary]="true"></p-checkbox>
									{{holster.shield ? 'Yes' : 'No'}}
								</div>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">The Kydex that touches your body will be higher up on the pistol
									to prevent perspiration on your pistol. This is usually found on IWB holsters.</div>
							</div>
						</p-accordionTab>
						<p-accordionTab [header]="'Kydex color - ' + holster.layer1">

							<div class="row" *ngIf="holster.layers == 1 || holster.layers == 2"
								style="margin-top: 20px;">
								<div class="col-lg-12">
									<div *ngFor="let color of colors" [pTooltip]="color.label"
										(click)="holster.layer1 = color.label"
										[ngStyle]="{'background-color': color.color}"
										style="cursor: pointer; display: block; width: 50px; height: 50px; float: left;">
										<i *ngIf="holster.layer1 == color.label" class="pi pi-check-circle"
											style="margin-left: 12px; margin-top: 14px; font-size: 24px; color: green;"></i>
									</div>
								</div>
							</div>


						</p-accordionTab>
						<p-accordionTab [header]="'Buckle - ' + holster.buckle">
							<div class="row">
								<div class="col-lg-12">
									<div pTooltip="Basic Clip" (click)="holster.buckle = 'Basic Clip'"
										style="margin: 10px; float: left; cursor: pointer; display: block; width: 100px; height: 100px; background-image: url(/assets/belt_clips/fomi.jpg); background-size: cover; background-repeat: no-repeat; background-position: center;">
										<i *ngIf="holster.buckle == 'Basic Clip'" class="pi pi-check-circle"
											style="margin-left: 38px; margin-top: 40px; font-size: 24px; color: green;"></i>
									</div>
									<div pTooltip="G-Code" (click)="holster.buckle = 'G-Code'"
										style="margin: 10px; float: left; cursor: pointer; display: block; width: 100px; height: 100px; background-image: url(/assets/belt_clips/gcode.jpg); background-size: cover; background-repeat: no-repeat; background-position: center;">
										<i *ngIf="holster.buckle == 'G-Code'" class="pi pi-check-circle"
											style="margin-left: 38px; margin-top: 40px; font-size: 24px; color: green;"></i>
									</div>

									<div pTooltip="Mono Block" (click)="holster.buckle = 'Mono Block'"
										style="margin: 10px; float: left; cursor: pointer; display: block; width: 100px; height: 100px; background-image: url(/assets/belt_clips/mono_block.jpg); background-size: cover; background-repeat: no-repeat; background-position: center;">
										<i *ngIf="holster.buckle == 'Mono Block'" class="pi pi-check-circle"
											style="margin-left: 38px; margin-top: 40px; font-size: 24px; color: green;"></i>
									</div>

									<div pTooltip="Tac Clip" (click)="holster.buckle = 'Tac Clip'"
										style="margin: 10px; float: left; cursor: pointer; display: block; width: 100px; height: 100px; background-image: url(/assets/belt_clips/tac.jpg); background-size: cover; background-repeat: no-repeat; background-position: center;">
										<i *ngIf="holster.buckle == 'Tac Clip'" class="pi pi-check-circle"
											style="margin-left: 38px; margin-top: 40px; font-size: 24px; color: green;"></i>
									</div>
								</div>
							</div>
							<div class="row" *ngIf="holster.buckle == 'Basic Clip'">
								<div class="col-lg-12">
									These "metal" universal flush mount belt clips are very practical when you want a
									thin profile (or tight ride) on your holster. The flush mount design is very
									versatile in the way it attaches, allowing you to
									simply slip it over your belt (this model fits up to 1.50 inch belts), molle straps,
									or simply over your waistband.
								</div>
							</div>

							<div class="row" *ngIf="holster.buckle == 'G-Code'">
								<div class="col-lg-12">
									This model is angled to help pull your firearm closer to your body. These are often
									used with IWB (inside-the-waistband) holster designs.
								</div>
							</div>

							<div class="row" *ngIf="holster.buckle == 'Mono Block'">
								<div class="col-lg-12">
									This clip design allows for a very secure carry on belts, waistlines, or
									any attachment point where the spring steel clip tabs can be positioned securely.
									Each spring tab has an integrated security clutch to prevent it from easily becoming
									detached from the mounting point.
								</div>
							</div>

							<div class="row" *ngIf="holster.buckle == 'Tac Clip'">
								<div class="col-lg-12">
									This clip is designed to allow adjustable cant for IWB mounting. These are flexible
									polymer USA made belt clips. They are excellent for belts and slings from 1.5" to
									1.75 inches.
								</div>
							</div>
						</p-accordionTab>
						<!-- <p-accordionTab header="Images">
							<div class="row">
								<div class="col-lg-12">
									<p>Images on the holster show up best when printed on white Kydex. Some images have a dark background and will color the kydex that color, making it no longer white. Because all holders have a buckle, screws, and various cut-outs, we'll adjust the image and placement for the best view and placement.</p>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-12" style="max-height: 300px; overflow-y: auto;">
									<div class="p-inputgroup">
										<input type="text" (keydown.enter)="findImage()" [(ngModel)]="search" pInputText class="form-control">
										<button type="button" pButton (click)="findImage()">Search</button>
									</div>
									<img *ngFor="let img of searchResults" [src]="img.assets.large_thumb.url">
								</div>
							</div>
							
						</p-accordionTab> -->
					</p-accordion>
					<div class="row" style="margin-top: 10px;">
						<div class="col-lg-12">
							<h3 style="color: white;">${{this.getTotal()}}</h3>
						</div>
					</div>
					<div class="select-pro">
						<div class="buttons">
							<button role="button" (click)="addToCart()" class="btn1">Add to cart</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row section5">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p-panel header="The Details">
					<h3>The holster</h3>
					<p>Our holsters are hand made and made to order. Crafted in Tampa, FL we follow your
						exact specifications above. We only use thick .080 Kydex and steel adjustable screws for rentention. </p>
					<h3>The warrenty</h3>
					<p>We take pride in the product we make and stand behind it. Let us know how we can help you and we'll try to resolve it.</p>
					<h3>Everything else</h3>
					<p>We're down for make something even more custom than what's listed above. Need a pistol not
						listed? Specific color? Bulk order? Message us and let's talk!</p>
				</p-panel>
			</div>
		</div>
	</div>
</section>