<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>About holsters.io</h2>
				</div>
			</div>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p style="text-align: justify;">Don't let the .io name fool you (please?!?). Good domain names are hard to come by now a days. Holsters.io is a family owned and operated small business out of Oldsmar, FL. We're a small city between Clearwater, Westchase, and Tampa.</p>
				<p style="text-align: justify;">Living in a tropical climate, we found it difficult to concreal carry our pistols. That's when Little Friend was born. It's our first product of hopefully, many. Engineered by Mario, it's design has been iterated over dozens of times and used in the real world for months. It's the perfect combination of functionality and form. Easy to use, easy to holster, and easy to draw. Most importantly, <b>it's safe</b>. </p>
				<p>OWB and IWB holsters aren't for everyone. When you're ready for something different, try My Little Friend and <b>Carry Responsibly</b>.</p>
			</div>
		</div>
	</div>
</section>