import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { AdminService } from 'src/app/admin/admin/admin.service';
import { ApplicationsService } from 'src/app/admin/application/application.service';

import { ApplicationComponent } from './application.component';
import { SelectButtonModule } from 'primeng/selectbutton';

const routes: Routes = [
  { path: 'application', component: ApplicationComponent }
]
@NgModule({
  declarations: [
    ApplicationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    SelectButtonModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)

  ],
  providers: [ApplicationsService, MessageService, AdminService],
  exports: [ApplicationComponent]
})
export class ApplicationModule { }
