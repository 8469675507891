import { Component, Input, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { OrdersService } from './orders.service';
@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
})
export class OrdersComponent implements OnInit {
	orders: any[] = [];
	loading: boolean = false;
	@Input() order: any;

	constructor(
		private ordersService: OrdersService
	) {

	}

	ngOnInit(): void {
		if(!this.order) {
			this.getData();

		}
	}

	getData() {
		this.loading = true;
		this.ordersService.getOrders().subscribe(res => {
			console.log(res);
			let count = 0;
			this.orders = res;
			this.orders.forEach(elem => {
				elem.id = count;
				elem.total = elem.amount.toFixed(2),
					count++;
			})
			this.loading = false;
			console.log(res);
		})
	}

	clear(table: Table) {
		table.clear();
	}
}
