import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';

import { GearComponent } from './gear.component';

import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import { CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';
import { DataViewModule } from 'primeng/dataview';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from 'src/app/services/products.service';

const routes: Routes = [
	{ path: 'gear', component: GearComponent }
]

@NgModule({
	declarations: [
		GearComponent,
	],
	imports: [
		DialogModule,
		ButtonModule,
		DataViewModule,
		CommonModule,
		FormsModule,
		TooltipModule,
		DropdownModule,
		InputTextModule,
		InputNumberModule,
		ToastModule,
		CarouselModule,
		ImageModule,
		RouterModule.forChild(routes),
	],
	providers: [MessageService, SystemService, HttpClient, ProductsService],
	exports: [GearComponent]
})
export class GearModule { }
