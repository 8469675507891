import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TermsComponent } from './terms.component';

import { TooltipModule } from 'primeng/tooltip';
import { SystemService } from 'src/app/services/system.service';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
	{ path: 'terms', component: TermsComponent }
]

@NgModule({
	declarations: [
		TermsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		TooltipModule,
		RouterModule.forChild(routes),
	],
	providers: [SystemService],
	exports: [TermsComponent]
})
export class TermsModule { }
