<app-admin-header></app-admin-header>
<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Users ( {{users.length}} )</h2>
				</div>
			</div>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
				<p-table *ngIf="!loading" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users" [rowsPerPageOptions]="[10,25,50]" [value]="users" dataKey="id" responsiveLayout="scroll">
					<ng-template pTemplate="header">
						<tr>
							<th style="width: 3rem"></th>
							<th>Name <p-sortIcon field="name"></p-sortIcon> <p-columnFilter type="text" field="name" display="menu"></p-columnFilter></th>
							<th>Email <p-sortIcon field="email"></p-sortIcon> <p-columnFilter type="text" field="email" display="menu"></p-columnFilter></th>
							<th>Points <p-sortIcon field="points"></p-sortIcon> <p-columnFilter type="text" field="points" display="menu"></p-columnFilter></th>
							<th>Last Visit <p-sortIcon field="last_visit"></p-sortIcon> <p-columnFilter type="date" field="last_visit" display="menu"></p-columnFilter></th>
							<th>Last Login <p-sortIcon field="last_login"></p-sortIcon> <p-columnFilter type="date" field="last_login" display="menu"></p-columnFilter></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-user let-ri="rowIndex" let-expanded="expanded">
						<tr>
							<td>
								<button type="button" pButton pRipple [pRowToggler]="user" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
							</td>
							<td>
								{{user.name}}
							</td>
							<td>
								{{user.email}}
							</td>
							<td>
								<input pInputText [(ngModel)]="user.points" (focusout)="updatePoints(user)" class="qty">
							</td>
							<td>{{user.last_visit}}</td>
							<td>{{user.last_login}}</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="rowexpansion" let-details>
						<tr>
							<td colspan="4">

							</td>
						</tr>
					</ng-template>

					<ng-template *ngIf="!users.length" pTemplate="summary">
						No users found.
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</section>