import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth/";
import { AngularFirestore, } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class OrderService {

	constructor(
		private db: AngularFirestore,

	) {

	}

	getOrders() {
		return this.db.collection('orders').valueChanges({ idField: 'id' });

	}

	updateOrder(payload, id) {
		return this.db.collection('orders').doc(id).update(payload);
	}

	deleteOrder(id) {
		return this.db.collection('orders').doc(id).delete();
	}
}