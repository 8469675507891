import { NgModule } from '@angular/core';

import { AdminNavComponent } from './nav.component';

@NgModule({
  declarations: [
    AdminNavComponent
  ],
  imports: [
  ],
  providers: [],
  exports: [AdminNavComponent]
})
export class AdminNavModule { }
