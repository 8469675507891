import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import * as pistols from 'src/assets/json/pistols.json';
import * as paracord from 'src/assets/json/paracord.json';
import * as colors from 'src/assets/json/colors.json';
import { DropdownFilterOptions } from 'primeng/dropdown';

@Component({
	selector: 'app-bracelets',
	templateUrl: './bracelets.component.html',
})
export class BraceletsComponent implements OnInit {
	bracelet = {
		type: "bracelet", size: { size: "10 inches" },
		qty: 1, price: 29.95,
		base: { "label": "Black", "image": "/assets/images/paracord/black.jpg" },
		color1: { "label": "Red", "image": "/assets/images/paracord/red.jpg" },
		color2: { "label": "White", "image": "/assets/images/paracord/white.jpg" },
		color3: { "label": "Royal Blue", "image": "/assets/images/paracord/royal_blue.jpg" }
	};

	manufacturer: any[] = (pistols as any).default['pistols'];
	paracord: any[] = (paracord as any).default['colors'];
	colors: any[] = (colors as any).default['colors'];
	filterValue: any;

	constructor(
		private messageService: MessageService,
		private systemService: SystemService
	) {

	}

	ngOnInit(): void {

	}

	sizes: any[] = [
		{ size: '6 inches' },
		{ size: '7 inches' },
		{ size: '8 inches' }
	]
	addToCart() {

		let cart = [];
		if (localStorage.getItem('cart')) { cart = JSON.parse(localStorage.getItem('cart')) };
		let item = {
			type: "bracelet",
			size: this.bracelet.size,
			base: this.bracelet.base,
			color1: this.bracelet.color1,
			color2: this.bracelet.color2,
			color3: this.bracelet.color3,
			price: this.bracelet.price,
			qty: this.bracelet.qty
		}
		cart.push(item);

		this.systemService.saveCartToStorage(cart);
		this.messageService.add({ key: "create", severity: 'success', summary: 'Cart', detail: 'Bracelet added!' });
		this.systemService.cartUpdated.next("");
	}

	myResetFunction(options: DropdownFilterOptions) {
		options.reset();
		this.filterValue = '';
	}
}
