import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class BoxDimensionCalculatorService {
	halfAddArrH(arr = [], { h, w, l }) {
		return arr.map((el, i, elx) => {
			if (i % 2 === 0) {
				const elNext = elx[i + 1] ? elx[i + 1] : {

				}
				return {
					...el,
					[h]: el[h] + (elNext[h] || 0),
					[w]: el[w] > (elNext[w] || 0) ? el[w] : elNext[w],
					[l]: el[l] > (elNext[l] || 0) ? el[l] : elNext[l],
				}
			}
			else return null
		}).filter(el => el)
	}
	halfAddArrW(arr = [], { h, w, l }) {
		return arr.map((el, i, elx) => {
			if (i % 2 === 0) {
				const elNext = elx[i + 1] ? elx[i + 1] : {

				}
				return {
					...el,
					[h]: el[h] > (elNext[h] || 0) ? el[h] : elNext[h],
					[w]: el[w] + (elNext[w] || 0),
					[l]: el[l] > (elNext[l] || 0) ? el[l] : elNext[l],
				}
			}
			else return null
		}).filter(el => el)
	}
	halfAddArrL(arr = [], { h, w, l }) {
		return arr.map((el, i, elx) => {
			if (i % 2 === 0) {
				const elNext = elx[i + 1] ? elx[i + 1] : {

				}
				return {
					...el,
					[h]: el[h] > (elNext[h] || 0) ? el[h] : elNext[h],
					[w]: el[w] > (elNext[w] || 0) ? el[w] : elNext[w],
					[l]: el[l] + (elNext[l] || 0),
				}
			}
			else return null
		}).filter(el => el)
	}
	heightDir(arr = [], key) {
		return arr.sort((a, b) => {
			return b[key] - a[key]
		})
	}
	getMin({ h, w, l }) {
		const min = Math.min(h, w, l)
		if (h === min) {
			return {
				h
			}
		} else if (w === min) {
			return {
				w
			}
		} else if (l === min) {
			return {
				l
			}
		} else {
			return {
				h
			}
		}
	}
	combinePack(arr) {
		if (arr.length > 1) {
			var hh = this.heightDir(arr, "h")[0] && this.heightDir(arr, "h")[0].h
			var hl = this.heightDir(arr, "l")[0] && this.heightDir(arr, "l")[0].l
			var hw = this.heightDir(arr, "w")[0] && this.heightDir(arr, "w")[0].w
			var min2 = this.getMin({
				h: hh,
				l: hl,
				w: hw,
			})
			var sKey = Object.keys(min2)[0];
			switch (sKey) {
				case "w":
					var items = this.halfAddArrW(arr, { h: "h", w: "w", l: "l" })
					return this.combinePack(items)
				case "h":
					var items = this.halfAddArrH(arr, { h: "h", w: "w", l: "l" })
					return this.combinePack(items)
				case "l":
					var items = this.halfAddArrL(arr, { h: "h", w: "w", l: "l" })
					return this.combinePack(items)
				default:
					var items = this.halfAddArrH(arr, { h: "h", w: "w", l: "l" })
					return this.combinePack(items)
			}
		}
		return arr
	}
	combineLoop(items) {
		var item = items
		while (item.length > 1) {
			item = this.combinePack(item)
		}
		return item
	}
}
