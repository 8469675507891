import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../pages/cart/cart.service';
import { SystemService } from '../services/system.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	@Input() auth: boolean = false;
	user = {
		email: "",
	}
	loggedIn: boolean = false;

	tot = 0;
	points = 0;

	menuItems: any[] = [];

	constructor(
		private router: Router,
		private systemService: SystemService,
		private cartService: CartService
	) {

	}

	ngOnInit(): void {
		this.loggedIn = false;
		this.loggedIn = this.systemService.funcCheckAuth();

		this.systemService.loginUpdated.subscribe(res => {
			console.log("loginUpdated got called");
			console.log(res);
			if (res) {
				this.loggedIn = true;
				// this.loggedIn = this.systemService.funcCheckAuth();
				this.user.email = sessionStorage.getItem('email');
				this.buildMenu();
				this.cartService.getProfile().subscribe(res => {
					console.log(res);
				});
			} else {
				this.loggedIn = false;
				this.buildMenu();
			}
		})
		if (this.loggedIn) {
			let payload = {
				last_visit: new Date().toString(),
			}
			this.systemService.putProfile(payload);

			this.cartService.getProfile().subscribe(res => {
				console.log(res);
			});
		}
		this.buildMenu();

		this.updateCart();
		this.systemService.cartUpdated.subscribe(res => {
			this.updateCart();
		})
	}

	buildMenu() {
		this.menuItems = [];
		this.menuItems = [{
			items: [
				{ label: 'Home', routerLink: ['/home'] },
				{ label: 'El Taco Holster', routerLink: ['/el-taco'] },
				{ label: 'Little Friend Trigger Guard', routerLink: ['/little_friend'] },
				{ label: 'Bracelets', routerLink: ['/bracelets'] },
				{ label: 'Gear', routerLink: ['/gear'] },
				{ label: 'Contact', routerLink: ['/contact'] },
				{ label: 'About', routerLink: ['/about'] },
				{ label: 'Cart', routerLink: ['/cart'] }
			]
		}];
		this.menuItems[0].items.push({ label: 'Order Lookup', routerLink: ['/order-lookup'] })
	}

	updateCart() {
		this.tot = 0;
		let cart = [];
		cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
		console.log("header cart", cart);
		if (cart) {
			this.tot = cart.length ? cart.length : 0;
			console.log("cart total", this.tot);
		} else {
			this.tot = 0;
		}
		if (!this.tot) { this.tot = 0; }
		console.log("cart total after if", this.tot);
	}

	gotoCart() {
		this.router.navigate(['/cart']);
	}

	gotoPoints() {
		this.router.navigate(['/points']);
	}
}
