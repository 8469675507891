<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Get in touch</h2>
				</div>
			</div>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<div class="row">
						<div class="col-lg-12">
							<p style="color: white;">Love us? Hate us? Wanna do a bulk order? (Yes please!) Need something not listed? Let us know below!</p>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 col-xs-12 feld">
							<input pInputText pTooltip="Required" [(ngModel)]="name" type="text" class="contact" placeholder="Full Name *">
						</div>
						<div class="col-sm-4 col-xs-12 feld">
							<input pInputText pTooltip="Required" [(ngModel)]="email" type="text" class="contact" placeholder="Email *">
						</div>
						<div class="col-sm-4 col-xs-12 feld">
							<input pInputText pTooltip="Required" [(ngModel)]="subject" type="text" class="contact" placeholder="Subject *">
						</div>
					</div>

					<div class="row" style="margin-top: 20px;">
						<div class="col-lg-12">
							<textarea pInputTextarea pTooltip="Required" [(ngModel)]="message" placeholder="Message *"></textarea>
						</div>
					</div>
					<div class="row" style="margin-top: 20px;">
						<div class="col-lg-12">
							<a pButton (click)="Send()" class="text-center btn1"><span>Send message</span></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>