import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProductsService } from 'src/app/services/products.service';
import { SystemService } from 'src/app/services/system.service';
@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
})
export class ProductsComponent implements OnInit {
	products: any[] = [];
	loading: boolean = true;

	package: any = {
		length: '',
		width: '',
		height: '',
		weight: ''
	}

	constructor(
		private systemService: SystemService,
		private productsService: ProductsService,
		private http: HttpClient,
		private confirmationService: ConfirmationService
	) {

	}

	ngOnInit(): void {
		this.getData();
	}

	getData() {
		this.loading = true;
		this.productsService.getAllProducts().subscribe(res => {
			console.log(res);
			this.products = res;
			this.loading = false;
		})
	}

	reOrder(event) {
		let count = 0;
		this.products.forEach(elem => {
			elem.order = count;
			this.productsService.updateProduct(elem.product_id, elem);
			count++;
		})
		this.getData();
	}

	updateStock(elem) {
		this.productsService.updateProduct(elem.product_id, { in_stock: elem.in_stock });
	}

	updateActive(elem) {
		this.productsService.updateProduct(elem.product_id, { active: elem.active });
	}

}
