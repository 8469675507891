import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import { UsersService } from './users.service';
@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
	users: any[] = [];
	loading: boolean = true;
	constructor(
		private systemService: SystemService,
		private usersService: UsersService,
		private messageService: MessageService
	) {

	}

	ngOnInit(): void {
		this.getData();

	}

	getData() {
		this.usersService.getUsers().subscribe(res => {
			this.users = res;
			this.loading = false;
		})
	}

	updatePoints(user) {
		let profile = {
			points: user.points
		}
		this.systemService.putProfile(profile).then(res => {
			this.messageService.add({ key: "general", severity: 'success', summary: 'Users', detail: 'Points updated' });
			this.getData();
		})
	}

}
