import { NgModule } from '@angular/core';

import { AdminHeaderComponent } from './header.component';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';
import {MenuModule} from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { SystemService } from 'src/app/services/system.service';
import { CartService } from 'src/app/pages/cart/cart.service';
@NgModule({
  declarations: [
    AdminHeaderComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    BadgeModule,
    MenuModule,
    ButtonModule,
  ],
  providers: [SystemService, CartService],
  exports: [AdminHeaderComponent]
})
export class AdminHeaderModule { }
