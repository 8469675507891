<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 col-md-6 col-sm-12 col-xs-12">
				<div class="products-photo">
					<!-- Tab panes -->
					<div class="tab-content">
						<div role="tabpanel" class="tab-pane active" id="img1">
							<img [src]="selectedSample.big" alt="" />
						</div>
					</div>
					<!-- Nav tabs -->
					<ul class="nav nav-tabs" role="tablist">
						<li role="presentation" class="active" *ngFor="let img of samples">
							<a (click)='selectedSample = img' role="tab" data-bs-toggle="tab"><img [src]="img.small" alt="" /></a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-7 col-md-6 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Customize Stowaway</h2>
					<p-accordion>
						<p-accordionTab header="Pistol" [selected]="true">
							<div class="row">
								<div class='col-lg-12'>
									<p-table [value]="holster.pistols" responsiveLayout="scroll">
										<ng-template pTemplate="header">
											<tr>
												<th>Make</th>
												<th>Model</th>
												<th>Light / Laser</th>
												<th>QTY</th>
												<th><button (click)="addPistol()" pTooltip="Add another holster with the same configuration" class='btn btn-info'><i class='pi pi-plus'></i></button></th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-pistol let-ri="rowIndex">
											<tr>
												<td>
													<p-dropdown [options]="manufacturer" [autoDisplayFirst]="false" [(ngModel)]="pistol.make" optionLabel="label" [showClear]="true" filter="true" appendTo="body"></p-dropdown>
												</td>
												<td>
													<p-dropdown *ngIf="pistol.make" [options]="pistol.make.models" [autoDisplayFirst]="false" [(ngModel)]="pistol.model" optionValue="label" optionLabel="label" [showClear]="true" filter="true" appendTo="body"></p-dropdown>
												</td>
												<td>
													<p-dropdown [options]="lights" [autoDisplayFirst]="false" [(ngModel)]="pistol.light" optionValue="label" optionLabel="label" [showClear]="true" filter="true" appendTo="body"></p-dropdown>
												</td>
												<td>
													<p-inputNumber [(ngModel)]="pistol.qty" [min]="1" class='qty'></p-inputNumber>
												</td>
												<td><button *ngIf="holster.pistols.length > 1" (click)="removePistol(ri)" class='btn btn-danger' pTooltip="Remove"><i class='pi pi-trash'></i></button></td>
											</tr>
										</ng-template>

									</p-table>
								</div>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<p>Want multiple holsters with the same design, but for different pistols? Click that + button to add more.</p>
								</div>
							</div>
						</p-accordionTab>
						<p-accordionTab [header]="'Color - ' + holster.layer1">
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<div *ngFor="let color of colors" [pTooltip]="color.label" (click)="holster.layer1 = color.label" [ngStyle]="{'background-color': color.color}" style="cursor: pointer; display: block; width: 50px; height: 50px; float: left;">
										<i *ngIf="holster.layer1 == color.label" class="pi pi-check-circle" style="margin-left: 12px; margin-top: 14px; font-size: 24px; color: green;"></i>
									</div>
								</div>
							</div>
						</p-accordionTab>
					</p-accordion>
					<div class="row" style="margin-top: 10px;">
						<div class="col-lg-12">
							<h3 style="color: white;">${{this.getTotal()}}</h3>
						</div>
					</div>
					<div class="select-pro">
						<div class="buttons">
							<button role="button" (click)="addToCart()" class="btn1">Add to cart</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row section5">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p-panel header="The Details">
					<h3>The Stowaway</h3>
					<p>Our holsters are hand crafted in Oldsmar, FL - a suburb of Tampa and hand made following your exact specifications above. We only use .080 Kydex and the finest felt liner if requested. All holsters are made to order and made 1 at a time.</p>
					<h3>The warrenty</h3>
					<p>Lifetime warrenties are bullshit. Holster making companies come and go with the wind. Contact us with your problem and we'll find a solution.</p>
					<h3>Everything else</h3>
					<p>We're down for make something even more custom than what's listed above. Need a pistol not listed? Specific color? Bulk order? Message us and let's talk!</p>
				</p-panel>
			</div>
		</div>
	</div>
</section>