import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';

import { AdminAmazonComponent } from './amazon.component';

import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { SystemService } from 'src/app/services/system.service';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { AdminHeaderModule } from '../header/header.module';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {


	canActivate(route: ActivatedRouteSnapshot) {
		let success = false;
		let user = sessionStorage.getItem('uid');
		console.log("User", user);
		if (user == 'mQTD3KZTH2dbu6D7I3wFoCxWIg73') { success = true; }
		return success;
	}

}
const routes: Routes = [
	{ path: 'admin/amazon', component: AdminAmazonComponent, canActivate: [AuthGuard] }
]

@NgModule({
	declarations: [
		AdminAmazonComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		TooltipModule,
		ButtonModule,
		TableModule,
		ProgressBarModule,
		AdminHeaderModule,
		InputTextModule,
		RouterModule.forChild(routes),
	],
	providers: [SystemService, MessageService],
	exports: [AdminAmazonComponent]
})
export class AdminAmazonModule { }
