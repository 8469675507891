<app-header [auth]="auth"></app-header>
<router-outlet></router-outlet>

<app-footer [auth]="auth"></app-footer>

<p-toast key='create' position="center">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-check-circle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <button type="button" pButton (click)="addAnother()" label="Create another?" class="p-button-success"></button>
                </div>
                <div class="col-lg-6">
                    <button type="button" pButton (click)="gotoCart()" label="Checkout" class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast key="general"></p-toast>