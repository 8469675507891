import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from 'src/app/services/system.service';
import { markdownTable } from 'markdown-table'
import { HttpClient } from '@angular/common/http';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
	selector: 'app-admin-login',
	templateUrl: './login.component.html',
})
export class AdminLoginComponent implements OnInit, OnDestroy {
	email: string = "";
	password: string = "";
	loading: boolean = false;

	constructor(
		private systemService: SystemService,
		private router: Router,
		private http: HttpClient,
		private analytics: AngularFireAnalytics
	) {

	}

	ngOnInit() {
		console.log("login");
	}

	ngOnDestroy() {

	}

	funcLogin() {
		if (!this.email) { return false; }
		if (!this.password) { return false; }
		this.loading = true;
		this.systemService.Login(this.email, this.password).then(res => {
			let payload = {
				last_login: new Date().toString(),
			}
			this.systemService.putProfile(payload);
			this.analytics.logEvent('login');
			this.analytics.setUserId(this.email);
			this.systemService.loginUpdated.next(true);
			this.loading = false;
			this.router.navigate(['/account']);

			let msg = "*User Logged in*\n";
			msg += "**" + this.email + "**";
			this.systemService.notifyDiscord(msg);
		})
		this.loading = false;
	}

	funcRegister() {
		if (!this.email) { return false; }
		if (!this.password) { return false; }
		this.loading = true;
		this.systemService.Register(this.email, this.password).then(async (res) => {
			// console.log(res);
			// if (res) {
			this.notifyDiscord(this.email);
			
			let payload = {
				last_login: new Date().toString(),
			}
			this.systemService.putProfile(payload);
			this.analytics.logEvent('registered');
			this.analytics.setUserId(this.email);
			this.systemService.loginUpdated.next(true);
			this.router.navigate(['/account']);
			// }
			this.loading = false;
		})
		this.loading = false;
	}

	notifyDiscord(email) {
		let msg = "*New User*\n";
		msg += "**" + email + "**";

		this.systemService.notifyDiscord(msg);
	}

}
