import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import {SelectButtonModule} from 'primeng/selectbutton';

import { AdminComponent } from './admin.component';
import { AdminService } from './admin.service';

const routes: Routes = [
	{ path: 'admin', component: AdminComponent }
]
@NgModule({
  declarations: [
    AdminComponent
  ],
  imports: [
	FormsModule,
  CommonModule,
  ProgressBarModule,
  InputTextareaModule,
  InputTextModule,
  SelectButtonModule,
	RouterModule.forChild(routes)

  ],
  providers: [AdminService, MessageService],
  exports: [AdminComponent]
})
export class AdminModule { }
