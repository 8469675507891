<div class="container-fluid ">
	<div class="row">
		<div class="col-sm-12 pd-0">
			<div class="bg-img">
				<div class="container position-relative home_bg">
					<div class="row">
						<div class="col-sm-12">
							<div class="contents text-center" style="width: 100vw;">
								<div class="animated fadeInUp" data-wow-duration="2s" style="margin-top: 40px;">
									<a href="/el-taco" title="customize your holster" class="btn1">Customize your
										El Taco Holster now</a>
									<!-- <p class="mr-lu mr-ru wow animated fadeInDown"
										style="font-size: 26px; margin-top: 20px; text-shadow: 3px 3px black;"
										data-wow-duration="1.5s">Everyday is
										Veterans day at holsters.io. Verify your veteran status and take 20% off every
										order.</p> -->

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="container-fluid" style="margin-top: 20px;">
	<div class="row">
		<div class="col-xs-12">
			<div class="item-thumbnail">

				<a href="/little_friend" class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="float: left;">
					<div class="items">
						<div class="dbox">
							<div class="dleft">
								<figure><img src="assets/images/thumb_little_friend.png" alt="Little Friend">
								</figure>
							</div>
							<div class="dright">
								<div class="content">
									<h3>Little Friend</h3>
									<small style="color: white;">Ambidextrous pocket carry trigger guard</small>
									<p>$29.95</p>
								</div>
							</div>
						</div>
					</div>
				</a>
				<a href="/bracelets" class="col-lg-4 col-md-6 col-sm-6 col-xs-12" style="float: left;">
					<div class="items">
						<div class="dbox">
							<div class="dleft">
								<figure>
									<img src="assets/images/bracelet_thumb.png" alt="">
								</figure>
							</div>
							<div class="dright">
								<div class="content">
									<h3>Bracelets</h3>
									<small style="color: white;">Unisex 550 Paracord bracelet</small>
									<p>$29.95</p>
								</div>
							</div>
						</div>
					</div>
				</a>
				<a href="/gear" class="col-lg-4 col-md-6 col-sm-6 col-xs-12" style="float: left;">
					<div class="items">
						<div class="dbox">
							<div class="dleft">
								<figure><img src="assets/images/apparel_thumb.png" alt="t-shirt"></figure>
							</div>
							<div class="dright">
								<div class="content">
									<h3>Gear</h3>
									<small style="color: white;">Logo wear, cleaning mats and coffee mugs!</small>
									<p>Starting at $19.95</p>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>

<section class="events-area section">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12">
				<div class="section-heading">
					<div class="item-thumbnail">
						<a href="/el-taco" class="col-sm-6 col-xs-12 home_img_ref">
							<div class="items">
								<div class="dbox">
									<div class="dleft">
										<figure><img src="assets/images/holsters/holster5_big.png" alt="El Taco"
												style="border-radius: 350px; max-width: 350px;">
										</figure>
									</div>
								</div>
							</div>
						</a>
						<a href="/el-taco" class="col-sm-6 col-xs-12 home_text_ref">
							<div class="items">
								<div class="dbox">
									<div class="dright">
										<div class="content">
											<h1 style="color: white; font-size: 66px; font-family: Audiowide,cursive;">
												El Taco Holster</h1>
											<small style="color: white; font-size: 20px;">Our holsters are hand made and
												made to order. Crafted in Tampa, FL we follow your exact specifications.
												Only premium materials are used. We are left handed, so no lefties left
												out either (puns intended)</small>
											<p style="font-size: 22px; margin: 0 auto; margin-top: 30px;"><span
													style='color: white'>Completely customizable for </span>$49.95</p>
											<p style="font-size: 16px; margin: 0 auto; margin-top: 30px;">Take $10 off little friend in the cart when you order both for the same pistol</p>
										</div>
									</div>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="events-area section">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12">
				<div class="section-heading">
					<div class="item-thumbnail">
						<a href="/little_friend" class="col-sm-6 col-xs-12 home_img_ref">
							<div class="items">
								<div class="dbox">
									<div class="dleft">
										<figure><img src="assets/images/home_little_friend.png" alt="Little Friend"
												style="border-radius: 350px; max-width: 350px;">
										</figure>
									</div>
								</div>
							</div>
						</a>
						<a href="/little_friend" class="col-sm-6 col-xs-12 home_text_ref">
							<div class="items">
								<div class="dbox">
									<div class="dright">
										<div class="content">
											<small style="color: white; font-size: 30px;">say hello to my</small>
											<h1 style="color: white; font-size: 86px; font-family: Audiowide,cursive;">
												Little Friend</h1>
											<small style="color: white; font-size: 20px;">Ambidextrous, conceal carry
												pocket trigger guard. Men or women. Shorts, pants, cross chest bag,
												coat, purse, it doesn't matter. If your pistol fits in a pocket and the
												carabiner can hook onto something, little friend will keep you safe and
												ready so you can <b>carry responsibly</b></small>
											<p style="font-size: 22px; margin: 0 auto; margin-top: 30px;"><span
													style='color: white'>Completely customizable for </span>$29.95</p>
										</div>
									</div>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- <section class="events-area section">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12">
				<div class="section-heading">
					<div class="item-thumbnail">
						<a href="/el-taco" class="col-sm-12 col-xs-12">
							<div class="items">
								<div class="dbox">
									<div class="dright">
										<div class="content">
											<h1 style="color: white; font-size: 86px; font-family: Audiowide,cursive;">
												El Taco Holster</h1>
											<small style="color: white; font-size: 20px;">We heard you. You want full
												size holsters. We're finalizing our design and we should be done in a
												few weeks. Left handed or right handed. IWB or OWB. .080 inch kydex for
												strength. In house sublimination printing for more designs than the
												competition offers. Anime? You got it. Italian flag? We'll do
												it. You design it, we'll make it.</small>
											<p style="font-size: 22px; margin: 0 auto; margin-top: 30px;"><span
													style='color: white'>Completely customizable</span></p>
										</div>
										<div class="content">
											<p style="font-size: 22px; margin: 0 auto; margin-top: 30px;">Notify me when El Taco is available</p>
											<div class="p-inputgroup">
												<input type="text" pInputText placeholder="your email address"
													[(ngModel)]="notifyMeEmail" class="form-control">
												<button type="button" (click)="notifyMe();" pButton label="Submit"
													styleClass="p-button-info"></button>
											</div>
											<p style="width: 100%;"><small>no pressure. no spam. we promise.</small></p>
										</div>
									</div>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->
<section class="events-area section">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12">
				<div class="section-heading">
					<h2>Carry Responsibly</h2>
					<p>Your safety is our top concern. Every Little Friend is molded to your exact pistol of
						choice.
						Premium Kydex, adjustable retention, stainless steel parts, and 550 paracord are all
						utilized to
						produce the best product we can. We can't tell you what to do when your pistol is
						outside of
						your holster, but if you want to <b>carry responsibly</b>, carry holsters.io</p>
				</div>
			</div>
		</div>
	</div>
</section>