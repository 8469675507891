import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DashboardService } from './dashboard.service';
@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './dashboard.component.html',
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
    users: any[] = [];
    orders: any[] = [];

    constructor(
        private messageService: MessageService,
        private dashboardService: DashboardService
    ) {

    }

    ngOnInit(): void {
        this.getUsers();
    }

    ngOnDestroy(): void {

    }

    getUsers() {
        this.dashboardService.getUsers().subscribe(res => {
            console.log(res);

            var d = new Date();
            d.setHours(0, 0, 0, 0);
            let today = (Date.parse(d.toString()) / 1000);
            res.forEach(elem => {
                var datum = Date.parse(elem.last_login);
                let ts = datum / 1000;
                if (ts > today) { this.users.push(elem); }
            })
        })
    }

    getOrders() {
        this.dashboardService.getOrders().subscribe(res => {
            console.log(res);

            var d = new Date();
            d.setHours(0, 0, 0, 0);
            let today = (Date.parse(d.toString()) / 1000);
            res.forEach(elem => {
                var datum = Date.parse(elem.dtetme);
                let ts = datum / 1000;
                if (ts > today) { this.orders.push(elem); }
            })
        })
    }
}
