<div style="margin-top: 20px;">
	<table style="min-width: 100%; width: 100%;">
		<tr>
			<td><b>Ship to</b></td>
		</tr>
		<tr>
			<td colspan="3">
				<b>Address 1</b><br>
				{{details.address1}}
			</td>
		</tr>
		<tr *ngIf="details.address2">
			<td colspan="3">
				<b>Address 2</b><br>
				{{details.address2}}
			</td>
		</tr>
		<tr>
			<td>
				<b>City</b><br>
				{{details.city}}
			</td>
			<td>
				<b>State</b><br>
				{{details.state}}
			</td>
			<td>
				<b>Postal Code</b><br>
				{{details.postal}}
			</td>

		</tr>
		<tr>
			<td colspan="3">
				<b>Country</b><br>
				{{details.country}}
			</td>
		</tr>

	</table>
</div>