import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SystemService } from 'src/app/services/system.service';
import { PanelModule } from 'primeng/panel';

import { AdminLoginComponent } from './login.component';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  { path: 'login', component: AdminLoginComponent }
]

@NgModule({
  declarations: [
    AdminLoginComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    CommonModule,
    ButtonModule,
    InputTextModule,
    ButtonModule,
    PanelModule,
    RouterModule.forChild(routes)

  ],
  providers: [SystemService],
  exports: [AdminLoginComponent]
})
export class AdminLoginModule { }
