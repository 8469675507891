export const environment = {
  production: true,
  firebase: {
    projectId: 'holsters-fc1c6',
    appId: '1:1049972185074:web:b9e25325a7c8c2ba24bd6b',
    databaseURL: 'https://holsters-fc1c6-default-rtdb.firebaseio.com',
    storageBucket: 'holsters-fc1c6.appspot.com',
    locationId: 'us-east1',
    apiKey: 'AIzaSyAnKW4yCCi4RKv0kxivQ3W-58G9kfmCOiA',
    authDomain: 'holsters-fc1c6.firebaseapp.com',
    messagingSenderId: '1049972185074',
    measurementId: 'G-EMDTTFQ289',
  }
};