
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationsService {
    constructor(
        private db: AngularFirestore
    ) {

    }
    getApplications() {
        return this.db.collection<any>('applications').valueChanges({ idField: 'id' })
    }

    deleteApplication(id: string) {
        return this.db
            .collection('applications')
            .doc(id)
            .delete();
    }

    postApplication(payload) {
        return this.db.collection('applications').add(payload);
    }
}