<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-4"></div>
			<div class="col-lg-4">
				<div class="single-product-content">
					<h2>Login</h2>
					<div class='p-grid'>
						<div class='p-col-12'>
							<label>Email Address</label>
							<input pInputText type='text' [(ngModel)]="email" class='form-control'>
						</div>
					</div>

					<div class='p-grid'>
						<div class='p-col-12'>
							<label>Password</label>
							<input pInputText type='password' [(ngModel)]="password" class='form-control'>
						</div>
					</div>

					<div class='p-grid' *ngIf="!loading">
						<div class='p-col-12'>
							<button pButton label="Login" (click)='funcLogin()' class='btn1'></button>
							<button pButton label="Register" (click)='funcRegister()' class="btn1"></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>