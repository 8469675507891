<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Dog tags - points that never expire</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img src="assets/images/270x400.png" style="display: block; margin: 0 auto;">
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<div class="single-product-content">
							<h2 style="padding-left: 0px;"><a href='/holsters' style="color: white;">Holsters</a></h2>
						</div>
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<span style='color: #fff;'>Earn 400 dogs tags</span>
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<span style='color: #fff;'>Cost: 1200 dogs tags</span>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img src="assets/images/270x400.png" style="display: block; margin: 0 auto;">
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<div class="single-product-content">
							<h2 style="padding-left: 0px;"><a href='/little_friend' style="color: white;">Little Friend</a></h2>
						</div>
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<span style='color: #fff;'>Earn 200 dogs tags</span>
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<span style='color: #fff;'>Cost: 600 dogs tags</span>
					</div>
				</div>
			</div>

			<!-- <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
				<img src="assets/images/270x400.png">
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<div class="single-product-content">
							<h2 style="padding-left: 0px;">1911 Grips</h2>
						</div>
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<span style='color: #fff;'>Earn 200 dogs tags</span>
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<span style='color: #fff;'>Cost: 600 dogs tags</span>
					</div>
				</div>
			</div> -->

			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img src="assets/images/270x400.png" style="display: block; margin: 0 auto;">
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<div class="single-product-content">
							<h2 style="padding-left: 0px;"><a href='/tshirts' style="color: white;">T-Shirts</a></h2>
						</div>
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<span style='color: #fff;'>Earn 100 dogs tags</span>
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
						<span style='color: #fff;'>Cost: 300 dogs tags</span>
					</div>
				</div>
			</div>
		</div>

	</div>
</section>