import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AngularFireAuth } from "@angular/fire/auth/";
import { AngularFirestore} from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root'
})
export class OrdersService {

	constructor(
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private db: AngularFirestore
	) {

	}

	getOrders() {
		return this.db.collection<any>('orders', ref => ref.where('userid', '==', sessionStorage.getItem('uid'))).valueChanges({idField: 'order_id'});
	}
}