<section *ngIf="done" class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<p>Thank you for your order! We'll be in touch via email soon. To check on your order status, please
					visit the <a href='/order-lookup' style="color: #878244;">Order Lookup</a> page</p>
			</div>
		</div>
	</div>
</section>
<section *ngIf="!done" class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
		<div class="row" *ngIf="!loading">
			<div class="col-lg-8">
				<div class="single-product-content">
					<h2>Your Cart</h2>
					<p *ngIf="!cart?.length">Your cart is empty</p>
					<p-table *ngIf="cart?.length" [value]="cart" dataKey="id" responsiveLayout="scroll">
						<ng-template pTemplate="header">
							<tr>
								<th scope="col" style="width: 3rem"></th>
								<th scope="col">Item</th>
								<th scope="col"></th>
								<th scope="col">QTY</th>
								<th scope="col">Price Each</th>
								<!-- <th>{{points_total ? points_total : 0 }} Dog Tags</th> -->
								<th scope="col"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-pistol let-ri="rowIndex" let-expanded="expanded">
							<tr>
								<td>
									<button type="button" pTooltip="Details" pButton pRipple [pRowToggler]="pistol"
										class="p-button-text p-button-rounded p-button-plain"
										[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
								</td>
								<td>{{pistol.type | titlecase}}</td>
								<td>
									<span *ngIf="pistol.type == 'el taco'">
										<b>{{pistol.make}}</b> {{pistol.model}}
									</span>
									<span *ngIf="pistol.type == 'little friend'">
										<b>{{pistol.make}}</b> {{pistol.model}}
									</span>
									<span *ngIf="pistol.type == 'tshirt'">
										<b>{{pistol.name}}</b>
									</span>
									<span *ngIf="pistol.type == 'accessory'">
										<b>{{pistol.name}}</b>
									</span>
									<span *ngIf="pistol.type == 'bracelet'">
										<b>{{pistol.size.size}}</b>
									</span>
								</td>
								<td>
									<p-inputNumber [min]="1" (onBlur)="updateQTY()" [(ngModel)]="pistol.qty"
										class="qty"></p-inputNumber>
								</td>
								<td>
									<div *ngIf="pistol.discount_applied">
										<span
											style="color: red; text-decoration: line-through;">${{pistol.original_price}}</span>
										${{pistol.price}}
									</div>
									<div *ngIf="!pistol.discount_applied">
										${{pistol.price}}
									</div>
								</td>
								<td><button (click)="removePistol(ri)" class='btn btn-danger' pTooltip="Remove"><i
											class='pi pi-trash'></i></button></td>
							</tr>
						</ng-template>
						<ng-template pTemplate="rowexpansion" let-details>
							<tr>
								<td colspan="7">
									<div style="display: block; width: 50%">
										<app-product-details [details]="details"></app-product-details>
									</div>
								</td>
							</tr>
						</ng-template>
						<ng-template *ngIf="!cart.length" pTemplate="summary">
							No items found in your cart.
						</ng-template>
					</p-table>

					<div *ngIf="cart.length"
						style="background-color: #323232; padding: 15px; color: white; border-left: 1px solid #000; border-right: 1px solid #000;">
						<div class="row">
							<div class="col-lg-12" style="margin-top: 20px;">
								Subtotal: ${{(subtotal / 100) | number : '.2-2'}}
							</div>

							<div *ngIf="vaDiscount > 0" class="col-lg-12" style="margin-top: 20px;">
								Veteran Discount: ${{(vaDiscount / 100) | number : '.2-2'}}
							</div>

							<!-- <div class="col-lg-12" style="margin-top: 20px;">
								Tax: ${{(salestax / 100) | number : '.2-2'}}
							</div> -->

							<!-- <div class="col-lg-12" *ngIf="shipping" style="cursor: pointer; margin-top: 20px;">
								<span>Shipping: ${{shipping | number : '.2-2'}}</span>
							</div> -->

							<div class="col-lg-12" *ngIf="shipping" style="color: #878244;">
								<small>All orders are made to order and have some lead time. Currently, all orders are
									shipping within 1 week of order.</small>
							</div>

							<div class="col-lg-12" style="margin-top: 20px;">
								Total: ${{(total / 100) | number : '.2-2'}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4" *ngIf="cart?.length">
				<div class="single-product-content">
					<h2>Your Details</h2>
					<p-accordion [activeIndex]="activeIndex" [multiple]="false">
						<!-- <p-accordionTab *ngIf="!loggedIn" header="Login" [selected]="true">
							<div class="row">
								<div class="col-lg-12">
									<label>Email</label><br>
									<input type="text" [(ngModel)]="user.email" pInputText class="form-control" />
								</div>
							</div>

							<div class="row">
								<div class="col-lg-12">
									<label>Password</label><br>
									<input type="password" [(ngModel)]="user.password" pInputText
										class="form-control" />
								</div>
							</div>

							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<a (click)="systemService.Login(user.email, user.password)" class="btn1">Login</a>
									<a (click)="systemService.Register(user.email, user.password)"
										class="btn1">Register</a>
								</div>
							</div>
						</p-accordionTab> -->

						<p-accordionTab header="Billing Information" [selected]="true">
							<div class="row">
								<div class="mt-4">
									<label>Email <i pTooltip="So we can communicate order status"
											class='pi pi-info-circle'></i></label><br>
									<input type="text" [(ngModel)]="user.email" pInputText class="mr-2 form-control" />
									<small *ngIf="errors.email" class="p-error">Email is required</small>
								</div>
							</div>

							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<label>Your Name</label><br>
									<input [(ngModel)]="user.name" pInputText style='width: 100%;' />
									<small *ngIf="errors.name" class="p-error">Name is required</small>
								</div>
							</div>

							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<label>Company Name</label><br>
									<input [(ngModel)]="user.company" pInputText style='width: 100%;' />
								</div>
							</div>

							<app-address (callback)="saveBilling($event);" [cart]="true" [user]="user"
								[address]="user.billing_address"
								[address_components]="user.billing_address_components"></app-address>
							<small *ngIf="errors.address" class="p-error">Address is required</small>

						</p-accordionTab>

						<p-accordionTab *ngIf="user.billing_address" header="Shipping Address">
							<a (click)="copyBilling()" style="cursor: pointer; color: #878244;">Same as billing
								info</a><br>
							<app-address (callback)="saveShipping($event);" [cart]="true" [user]="user"
								[address]="user.shipping_address"
								[address_components]="user.shipping_address_components"></app-address>
							<small *ngIf="errors.shipping" class="p-error">Address is required</small>

						</p-accordionTab>

						<p-accordionTab *ngIf="user.shipping_address"
							[header]="user.vaverified == 'confirmed' ? 'Veteran Verified' : 'Veteran? Get 20% off!'">
							<p-progressBar *ngIf="loadingVA" mode="indeterminate"></p-progressBar>
							<div *ngIf="!loadingVA">
								<div *ngIf="user.vaverified == 'confirmed'">
									<p>Thank you for your service. Enjoy this 20% discount for everything you've done
										and sacraficed.</p>
								</div>
								<div *ngIf="!user.vaverified || (user.vaverified == 'not confirmed')">

									<div class="row">
										<div class="col-lg-12">
											<p>Verify your veteran status with the VA below and receive 20% off of every
												order. It takes just a moment and only needs to be done once. The
												information we collect is transmitted directly to va.gov and secured on
												both ends.</p>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-12">
											<label>First Name</label><br>
											<input pInputText [(ngModel)]="user.first_name">
										</div>
									</div>
									<div class="row">
										<div class="col-lg-12">
											<label>Middle Name</label><br>
											<input pInputText [(ngModel)]="user.middle_name">
										</div>
									</div>
									<div class="row">
										<div class="col-lg-12">
											<label>Last Name</label><br>
											<input pInputText [(ngModel)]="user.last_name">
										</div>
									</div>

									<div class="row">
										<div class="col-lg-12">
											<label>SS#</label><br>
											<p-inputMask [(ngModel)]="user.ssnum" mask="999-99-9999"></p-inputMask>
										</div>
									</div>

									<div class="row">
										<div class="col-lg-12">
											<label>Date of birth</label><br>
											<p-inputMask [(ngModel)]="user.dob" mask="9999-99-99" [(ngModel)]="user.dob"
												slotChar="yyyy-mm-dd"></p-inputMask>

										</div>
									</div>

									<div class="row" style="margin-top: 20px;">
										<div class="col-lg-12">
											<button pButton (click)="verifyVA()" class="btn1">Verify</button>
										</div>
									</div>
								</div>

							</div>
						</p-accordionTab>
						<p-accordionTab *ngIf="(total == 0) && cart?.length" header="Complete Order">
							<button *ngIf="!loadingPay" (click)="finishPayment()" class="btn1">Finish</button>
						</p-accordionTab>
						<p-accordionTab *ngIf="(total > 0) && cart?.length && user?.shipping_address_components"
							header="Pay with Debit / Credit">
							<div class="row">
								<div class="col-lg-12">
									<ng-container *ngIf="elementsOptions?.clientSecret">
										<ngx-stripe-payment
											[clientSecret]="elementsOptions?.clientSecret"></ngx-stripe-payment>
									</ng-container>
								</div>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<button *ngIf="!loadingPay" (click)="collectPayment()" class="btn1">PAY</button>
									<p-progressBar *ngIf="loadingPay" mode="indeterminate"></p-progressBar>
								</div>
							</div>
						</p-accordionTab>
					</p-accordion>
				</div>
			</div>
		</div>
	</div>
</section>