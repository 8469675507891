import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AddressComponent } from './address.component';

import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

@NgModule({
	declarations: [
		AddressComponent,
	],
	imports: [
		ButtonModule,
		FormsModule,
		CommonModule,
		TooltipModule,
		InputTextModule,
		GooglePlaceModule,
	],
	providers: [],
	exports: [AddressComponent]
})
export class AddressModule { }
