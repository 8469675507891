import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { OrderLookupService } from './orders_lookup.service';
@Component({
	selector: 'app-order-lookup',
	templateUrl: './orders_lookup.component.html',
})
export class OrderLookupComponent implements OnInit {
	orders: any[] = [];
	loading: boolean = false;
	order_id: any;
	order: any = {
		email: "",
		name: "",
		company: "",
	}

	constructor(
		private ordersService: OrderLookupService
	) {

	}

	ngOnInit(): void {

	}

	Find() {
		this.order = null;
		this.ordersService.getOrders(this.order_id).subscribe(res => {
			console.log(res);
			this.order = res ? res : {email: ""};
		})
	}
}
