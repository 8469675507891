import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ProductsService } from 'src/app/services/products.service';
import { SystemService } from 'src/app/services/system.service';

@Component({
	selector: 'app-gear',
	templateUrl: './gear.component.html',
})
export class GearComponent implements OnInit {
	products: any[] = [];
	showDetails: boolean = false;
	selectedItem: any = null;
	showNotify: boolean = false;
	notifyMeEmail: string = "";
	loading: boolean = false;

	constructor(
		private messageService: MessageService,
		private systemService: SystemService,
		private http: HttpClient,
		private productsService: ProductsService
	) {

	}

	ngOnInit(): void {
		this.getData();
		this.notifyMeEmail = sessionStorage.getItem('email') ? sessionStorage.getItem('email') : "";
	}

	getData() {
		this.loading = true;
		this.productsService.getProducts().subscribe(res => {
			this.products = res;
			this.loading = false;
		})
	}

	addToCart(item) {
		let cart = [];
		if (localStorage.getItem('cart')) { cart = JSON.parse(localStorage.getItem('cart')) };
		console.log(cart)
		let payload = {
			type: item.type,
			name: item.name,
			external_variant_id: item.size ? item.size.external_variant_id : item.external_variant_id,
			qty: item.qty,
			price: item.price,
			image: item.images[0],
			size: item.size,
			color: item.color
		}
		cart.push(payload);
		console.log("Cart", cart);
		localStorage.setItem('cart', JSON.stringify(cart));
		this.messageService.add({ key: "create", severity: 'success', summary: 'Cart', detail: item.name + ' added!' });
		this.systemService.cartUpdated.next("");
	}

	notifyMe() {

		this.productsService.notifyMe(this.selectedItem.product_id, this.notifyMeEmail);
		this.showNotify = false;
		this.messageService.add({ key: "general", severity: 'success', summary: 'Thanks!', detail: "We'll notify you as soon as this product is back in stock" });
	}
}
