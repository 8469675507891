<div (click)="op.toggle($event)" style="width: 25px; height: 25px; display: block; cursor: pointer;" [pTooltip]="selectedColor.label"  [ngStyle]="{'background-image': 'url(' + selectedColor.image + ')'}"></div>

<p-overlayPanel #op appendTo="body">
    <ng-template pTemplate>
        <div *ngFor="let color of paracord; let i = index" (click)="setColor(color, op)" [pTooltip]="color.label" (click)="selectedColor = color" [ngStyle]="{'background-image': 'url(' + color.image + ')', 'clear': getRow(i)}" style="cursor: pointer; display: block; width: 50px; height: 50px; float: left;">
            <i *ngIf="selectedColor.label == color.label" class="pi pi-check-circle" style="margin-left: 13px; margin-top: 14px; text-shadow: .5px .5px black; font-size: 24px; color: green;"></i>
        </div>
    </ng-template>
</p-overlayPanel>

