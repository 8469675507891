<header style="position: relative; height: 0px; margin-top: 110px;">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12 col-sm-10 hidden-xs d-md-block d-xs-none d-none">
				<div class="main-header">
					<div class="main-menus">
						<nav>
							<ul class="mamnu">
								<li><a href="/admin/dashboard">Dashboard</a></li>
								<li><a href="/admin/products">Products</a></li>
								<li><a href="/admin/orders">Orders</a></li>
								<li><a href="/admin/users">Users</a></li>
								<li><a href="/admin/pistols">Pistols</a></li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
