import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SystemService } from 'src/app/services/system.service';
@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit {
    password: string = "";
    confirm: string = "";
    mode: any;
    code: any;

    constructor(
        private activatedActivated: ActivatedRoute,
        private systemService: SystemService
    ) {

    }


    ngOnInit(): void {
        this.mode = this.activatedActivated.snapshot.queryParams['mode'];
        this.code = this.activatedActivated.snapshot.queryParams['oobCode'];

    }

    resetPassword() {
        if (this.password !== this.confirm) { return false; }
        this.systemService.resetPassword(this.code, this.password).then(res => {
            
        })

    }
}
