import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';


import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import { GripComponent } from './1911.component';
import {CarouselModule} from 'primeng/carousel';
import {ScrollPanelModule} from 'primeng/scrollpanel';

const routes: Routes = [
	{ path: '1911', component: GripComponent }
]

@NgModule({
	declarations: [
		GripComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		TooltipModule,
		AccordionModule,
		RadioButtonModule,
		DropdownModule,
		CheckboxModule,
		TableModule,
		InputTextareaModule,
		InputNumberModule,
		TabViewModule,
		ToastModule,
		CarouselModule,
		ScrollPanelModule,
		RouterModule.forChild(routes),
	],
	providers: [MessageService, SystemService],
	exports: [GripComponent]
})
export class Grip1911Module { }
