<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<label>Enter your order # below</label><br>
				<div class="p-inputgroup">
					<input type="text" (keydown.enter)="Find()" [(ngModel)]="order_id" pInputText class="form-control">
					<button type="button" pButton (click)="Find()">Find Order</button>
				</div>
			</div>
		</div>
	</div>
</section>

<section *ngIf="!order.email" class="product-details section" style="padding-top: 0px; color: white;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p>No order found</p>
			</div>
		</div>
	</div>
</section>
<section *ngIf="order?.email" class="product-details section" style="padding-top: 0px; color: white;">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<div class="row">
					<div class="col-lg-12">
						<label>Status</label><br>
						{{order.status}}
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12">
						<label>Date / Time placed</label><br>
						{{order.dtetme}}
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12">
						<label>Email</label><br>
						{{order.email}}
					</div>
				</div>
				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12">
						<label>Name</label><br>
						<span>{{order.name}}</span>
					</div>
				</div>

				<div class="row" *ngIf="order.company" style="margin-top: 20px;">
					<div class="col-lg-12">
						<label>Company Name</label><br>
						<span>{{order.company}}</span>
					</div>
				</div>

				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12">
						<p><b>Total: </b>${{order.amount}}</p>
					</div>
				</div>

				<div class="row" style="margin-top: 20px;">
					<div class="col-lg-12">
						<p>Ships to:</p>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<label>Address 1</label><br>
						{{order.shipping_components.address1}}
					</div>
				</div>

				<div class="row" *ngIf="order.shipping_components.address2">
					<div class="col-lg-12">
						<label>Address 2</label><br>
						{{order.shipping_components.address2}}
					</div>
				</div>

				<div class="row">
					<div class="col-lg-4">
						<label>City</label><br>
						{{order.shipping_components.city}}
					</div>
					<div class="col-lg-4">
						<label>State</label><br>
						{{order.shipping_components.state}}
					</div>
					<div class="col-lg-4">
						<label>Postal Code</label><br>
						{{order.shipping_components.postal}}
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<label>Country</label><br>
						{{order.shipping_components.country}}
					</div>
				</div>
			</div>
			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<div *ngFor="let item of order.cart">
					<app-product-details [details]="item"></app-product-details>
				</div>
			</div>
		</div>
	</div>
</section>