import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContactComponent } from './contact.component';

import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
	{ path: 'contact', component: ContactComponent }
]

@NgModule({
	declarations: [
		ContactComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		TooltipModule,
		InputTextModule,
		InputTextareaModule,
		ButtonModule,
		TooltipModule,
		RouterModule.forChild(routes),
	],
	providers: [MessageService, SystemService],
	exports: [ContactComponent]
})
export class ContactModule { }
