<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="single-product-content">
					<h2>Gear</h2>
				</div>
			</div>
			<p><b>Complete transparancy</b> These products are produced by a 3rd party print on demand service. While we
				try to source all of our materials from USA based companies, these may or may not be produced in the
				USA. </p>
			<p-dataView #dv [value]="products" [loading]="loading" [paginator]="true" [rows]="10" filterBy="name">
				<ng-template pTemplate="header">
					<input type="search" pInputText placeholder="Search by name"
						(input)="dv.filter($event.target.value, 'contains')">
				</ng-template>
				<ng-template let-item pTemplate="listItem">
					<div class="col-lg-4">
						<div class="row">
							<div class="col-lg-12">
								<p-carousel [value]="item.images" [numVisible]="1" [numScroll]="1">
									<ng-template let-img pTemplate="item">
										<p-image appendTo="body" [src]="img.image" alt="Image" width="250"
											(click)="selectedItem = item; showDetails = !showDetails"></p-image>
									</ng-template>
								</p-carousel>
							</div>
						</div>
						<div class="row" style="margin-top: 20px;">
							<div class="col-lg-12 text-center" style="color: #fff;">
								<h2>{{item.name}}</h2>
							</div>
							<div class="col-lg-12 text-center" style="color: #fff; font-size: 18px;">
								${{item.price}}
							</div>
						</div>

						<div class="row" style="margin-top: 20px;">
							<div class="col-lg-12" style="text-align: center;">
								<p-dropdown *ngIf="item.sizes" [options]="item.sizes" optionLabel="size" appendTo="body"
									[(ngModel)]="item.size"></p-dropdown>
								<p-inputNumber [min]="1" [(ngModel)]="item.qty" class="qty"></p-inputNumber>
							</div>
						</div>
						<div class="row" style="margin-top: 20px;">
							<div class="col-lg-12 text-center">
								<button *ngIf="item.in_stock" (click)="addToCart(item)" pButton class="btn1">Add to
									Cart</button>
								<button *ngIf="!item.in_stock" pButton class="btn1"
									(click)="selectedItem = item; showNotify = true;"
									style="background-color: #cdcdcd;">Out of stock</button>
								<a (click)="selectedItem = item; showDetails = !showDetails;"
									style="display: block; clear: both; margin-top: 10px; margin-bottom: 10px; color: white; cursor: pointer;">Details</a>
							</div>
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate="paginatorleft" let-state>
				</ng-template>
				<ng-template pTemplate="paginatorright">
				</ng-template>
				<ng-template let-item pTemplate="paginatordropdownitem">
					{{item.value}} - per page
				</ng-template>
			</p-dataView>
		</div>
	</div>
</section>

<p-dialog header="The details" *ngIf="showDetails" [(visible)]="showDetails">
	<p [innerHTML]="selectedItem.description ? selectedItem.description : selectedItem.desc"></p>
	<div class="row" style="margin-top: 20px;">
		<div class="col-lg-12" style="text-align: center;">
			<p-dropdown *ngIf="selectedItem.sizes" [options]="selectedItem.sizes" optionLabel="size" appendTo="body"
				[(ngModel)]="selectedItem.size"></p-dropdown>
			<p-inputNumber [min]="1" [(ngModel)]="selectedItem.qty" class="qty"></p-inputNumber>
		</div>
	</div>
	<div class="row" style="margin-top: 20px;">
		<div class="col-lg-12 text-center">
			<button *ngIf="selectedItem.in_stock" (click)="addToCart(selectedItem)" pButton class="btn1">Add to
				Cart</button>
			<button *ngIf="!selectedItem.in_stock" pButton class="btn1" style="background-color: #cdcdcd;">Out of
				stock</button>

		</div>
	</div>
</p-dialog>

<p-dialog header="Notify me" *ngIf="showNotify" [(visible)]="showNotify">
	<div style="max-width: 400px;">
		<p>Sorry this product is out of stock. We're not happy about it either. If you want us to notify you when it's
			back
			in stock, enter in your email address below. We promise, no spam.</p>
		<div class="row">
			<div class="col-xs-12">
				<label>Email address</label><br>
				<div class="p-inputgroup">
					<input type="text" pInputText [(ngModel)]="notifyMeEmail" class="form-control">
					<button type="button" (click)="notifyMe();" pButton label="Submit"
						styleClass="p-button-info"></button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>