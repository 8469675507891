<div class="p-grid" style="margin: 20px;">
	<div class="p-col-12">
		<h3>Job Applications</h3>
		<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>

		<p-table *ngIf="!loading" [value]="applications">
			<ng-template pTemplate="header">
				<tr>
					<th>Name</th>
					<th>Date</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-application>
				<tr>
					<td>{{application.name}}</td>
					<td>{{application.dtetme}}</td>
					<td>
						<button (click)="viewApplication(application)" icon="pi pi-eye" pTooltip="View" pButton class="p-button-info"></button>
						<button (click)="deleteApplication(application)" icon="pi pi-trash" pTooltip="Delete" pButton class="p-button-danger"></button>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<p-dialog *ngIf="selectedApplication" [header]="selectedApplication.name" [(visible)]="toggleOverlay" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '75vw'}">
	<div class="p-grid">
		<div class='p-col-6'>
			<label>Name</label>
			{{selectedApplication.name}} 
		</div>
		<div class='p-col-3'>
			<label>Date of Birth</label>
			{{selectedApplication.dob}}
		</div>
		<div class='p-col-3'>
			<label>Email Address</label>
			{{selectedApplication.email}}
		</div>
	</div>
	<div class="p-grid">
		<div class='p-col-12'>
			<label>Address line 1</label>
			{{selectedApplication.address1}}
		</div>
	</div>
	<div class="p-grid">
		<div class='p-col-12'>
			<label>Address line 2</label>
			{{selectedApplication.address2}}
		</div>
	</div>
	<div class="p-grid">
		<div class='p-col-4'>
			<label>City</label>
			{{selectedApplication.city}}
		</div>

		<div class='p-col-4'>
			<label>Zipcode</label>
			{{selectedApplication.zipcode}}
		</div>

		<div class='p-col-4'>
			<label>Phone</label>
			{{selectedApplication.phone}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>Position you are applying for</label>
			{{selectedApplication.position}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>Do you have the right to work in the U.S.A.</label>
			{{selectedApplication.citizen.name}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>Are you over the age of 18</label>
			{{selectedApplication.age.name}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>Have you been convicted of a crime (not including infractions)</label>
			{{selectedApplication.crime.name}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>Requested start date</label>
			{{selectedApplication.startdte}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>High School or Trade School</label>
			{{selectedApplication.highschool}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>College</label>
			{{selectedApplication.college}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>Military Service</label>
			{{selectedApplication.military}}
		</div>

		<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
			<label>Reference Name + phone or email</label>
			{{selectedApplication.reference}}
		</div>
	</div>

	<div class="p-col-12">
		<div class="p-grid">
			<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
				<label>Current Employer</label>
				{{selectedApplication.c1}}
			</div>

			<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
				<label>Start Date</label>
				{{selectedApplication.c1dte}}
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
				<label>Previous Employer 1</label>
				{{selectedApplication.p1}}
			</div>

			<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
				<label>Start Date</label>
				{{selectedApplication.p1startdte}}
			</div>

			<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
				<label>End Date</label>
				{{selectedApplication.p1enddte}}
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<div class="p-grid">
			<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
				<label>Previous Employer 2</label>
				{{selectedApplication.p2}}
			</div>

			<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
				<label>Start Date</label>
				{{selectedApplication.p2startdte}}
			</div>

			<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
				<label>End Date</label>
				{{selectedApplication.p2enddte}}
			</div>
		</div>
	</div>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"></p-confirmDialog>