import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import * as pistols from 'src/assets/json/pistols.json';

@Component({
	selector: 'app-amazon',
	templateUrl: './amazon.component.html',
})
export class AdminAmazonComponent implements OnInit {

	manufacturer: any[] = (pistols as any).default['pistols'];
    pistols: any = [];

	constructor(
		private systemService: SystemService,
		private messageService: MessageService
	) {

	}

	ngOnInit(): void {
		this.getData();

	}

	getData() {
        this.manufacturer.forEach(elem => {
            elem.models.forEach(model => {
                this.pistols.push({model: elem.label + " " + model.label});
            });
        })
    }

	

}
