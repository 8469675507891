import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AdminService } from './admin.service';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit, OnDestroy {
	admin: any = { banner: "", hiring: "", phone: "", email: "" }
	loading: boolean = false;
	hiringOptions: any[] = [{name: "Yes"}, {name: "No"}];

	constructor(
		private adminService: AdminService,
		private messageService: MessageService
	) {

	}

	ngOnInit(): void {
		this.getData();
	}

	ngOnDestroy(): void {

	}

	getData() {
		this.loading = true;
		this.adminService.getAdmin().subscribe(res => {
			console.log(res);
			this.admin = res ? res : {admin: "", banner: "", phone: "", email: "", hiringToggle: "Yes"};

			this.loading = false;
		})
	}

	saveAdmin() {
		console.log(this.admin);
		this.adminService.putAdmin(this.admin).then(res => {
			console.log(res);
			this.messageService.add({ severity: 'success', summary: 'System', detail: 'Save Successful' });
			this.getData();
		})
	}
}
