<div [ngSwitch]="details.type">
	<div *ngSwitchCase="'el taco'" style="margin-top: 10px;">
		<table style="min-width: 100%; width: 100%;" class="table_details">
			<tr><td><b>El Taco</b></td></tr>
			<tr><td>Make</td><td>{{details.make | titlecase}}</td></tr>
			<tr><td>Model</td><td>{{details.model | titlecase}}</td></tr>
			<tr><td>Light / Laser</td><td>{{details.light | titlecase}}</td></tr>
			<tr><td>Notes</td><td>{{details.notes ? details.notes : "none"}}</td></tr>
			<tr><td>Hand</td><td>{{details.hand | titlecase}}</td></tr>
			<tr><td>Style</td><td>{{details.style | titlecase}}</td></tr>
			<tr><td>Layer 1</td><td>{{details.layer1 | titlecase}}</td></tr>
			<tr><td>Buckle</td><td>{{details.buckle | titlecase}}</td></tr>
			<tr><td>Sweatshield</td><td>{{details.shield ? "Yes" : "No"}}</td></tr>
			<tr><td>Optics cut</td><td>{{details.reddot ? "Yes" : "No"}}</td></tr>
		</table>
	</div>

	<div *ngSwitchCase="'little friend'" style="margin-top: 10px;">
		<table style="min-width: 100%; width: 100%;" class="table_details">
			<tr><td><b>Little Friend</b></td></tr>
			<tr><td>Make</td><td>{{details.make | titlecase}}</td></tr>
			<tr><td>Model</td><td>{{details.model | titlecase}}</td></tr>
			<tr><td>Color</td><td>{{details.color | titlecase}}</td></tr>
			<tr><td>Base Color</td><td>{{details.base.label }}</td></tr>
			<tr><td>Strand 1</td><td>{{details.color1.label }}</td></tr>
			<tr><td>Strand 2</td><td>{{details.color2.label }}</td></tr>
			<tr><td>Strand 3</td><td>{{details.color3.label }}</td></tr>
		
		</table>
	</div>

	<div *ngSwitchCase="'tshirt'" style="margin-top: 10px;">
		<table style="min-width: 100%; width: 100%;" class="table_details">
			<tr><td><b>{{details.name}}</b></td></tr>
			<tr><td>Size</td><td>{{details.size.size }}</td></tr>
			<tr><td>Color</td><td>{{details.color }}</td></tr>
			<tr><td colspan="2"><img [src]='details.image.image' style="max-height: 250px;"></td></tr>
		</table>
	</div>
	<div *ngSwitchCase="'accessory'" class="table_details" style="margin-top: 10px;">
		<table style="min-width: 100%; width: 100%;">
			<tr><td><b>{{details.name }}</b></td></tr>
			<tr><td colspan="2"><img [src]='details.image.image' style="max-height: 250px;"></td></tr>
		</table>
	</div>

	<div *ngSwitchCase="'bracelet'" class="table_details" style="margin-top: 10px;">
		<table style="min-width: 100%; width: 100%;">
			<tr><td><b>Bracelet</b></td></tr>
			<tr><td>Size</td><td>{{details.size.size }}</td></tr>
			<tr><td>Base Color</td><td>{{details.base.label }}</td></tr>
			<tr><td>Strand 1</td><td>{{details.color1.label }}</td></tr>
			<tr><td>Strand 2</td><td>{{details.color2.label }}</td></tr>
			<tr><td>Strand 3</td><td>{{details.color3.label }}</td></tr>
		</table>
	</div>

	<!-- <div *ngSwitchCase="'grip'">
		<table style="min-width: 100%; width: 100%;">
			<tr><td>Color</td><td>{{details.color }}</td></tr>
			<tr><td>Infill color</td><td>{{details.infill }}</td></tr>			
		</table>
	</div> -->
</div>