import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/pages/cart/cart.service';
import { SystemService } from '../../services/system.service';

@Component({
	selector: 'app-admin-header',
	templateUrl: './header.component.html',
})
export class AdminHeaderComponent implements OnInit {
	@Input() auth: boolean = false;
	user = {
		email: "",
	}
	loggedIn: boolean = false;

	tot = 0;
	points = 0;

	menuItems: any[] = [];
	
	constructor(
		private router: Router,
		private systemService: SystemService,
		private cartService: CartService

	) {

	}

	ngOnInit(): void {
		this.loggedIn = this.systemService.funcCheckAuth();
		
		this.systemService.loginUpdated.subscribe(res => {
			if (res['success']) {

				this.loggedIn = this.systemService.funcCheckAuth();
				this.user.email = sessionStorage.getItem('email');
				this.buildMenu();
			}
		})
		this.buildMenu();
		this.systemService.cartUpdated.subscribe(res => {
			this.updateCart();
		})
		this.updateCart();

	}

	buildMenu() {
		this.menuItems = [];
		this.menuItems = [{
			items: [
				{ label: 'Home', routerLink: ['/home'] },
				{ label: 'Holsters', routerLink: ['/holsters'] },
				{ label: 'Little Friend', routerLink: ['/little_friend'] },
				{ label: '1911 Grips', routerLink: ['/1911'] },
				{ label: 'CC T-Shirts', routerLink: ['/tshirts'] },
				{ label: 'About', routerLink: ['/about'] },
				{ label: 'Contact', routerLink: ['/contact'] },
				{ label: 'Cart', routerLink: ['/cart'] }
			]
		}];
		if (this.loggedIn) {
			this.menuItems.push({ label: 'My account', routerLink: ['/account'] })
		}
	}
	updateCart() {
		this.tot = 0;
		let cart = [];
		cart = JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : [];
		if (cart.length) {
			this.tot = cart.length;
		}

		
	}

	gotoCart() {
		this.router.navigate(['/cart']);
	}

	gotoPoints() {
		this.router.navigate(['/points']);
	}
}
