import { Component, OnInit } from '@angular/core';
@Component({
	selector: 'app-points',
	templateUrl: './points.component.html',
})
export class PointsComponent implements OnInit {
	constructor(
	) {

	}

	ngOnInit(): void {

	}
}
