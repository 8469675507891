import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth/";
import { AngularFirestore, } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class CartService {


	constructor(
		private db: AngularFirestore,

	) {

	}



	getProfile() {
		if (!sessionStorage.getItem('uid')) { return; }
		return this.db.collection<any>('profile').doc(sessionStorage.getItem('uid')).valueChanges()
	}

	putOrder(payload) {
		// if (!sessionStorage.getItem('uid')) { return; }
		return this.db.collection('orders').add(payload).then(ref => {
			return { uid: ref.id }
		});
	}

	genEmail(cart): string {
		let out: string = "";
		out = "<table style='width: 100%;'>";
		out += "<tr>";
		out += "<th style='text-align: left; width: 50%;'>Item</th><th style='text-align: left; width: 50%;'>QTY</th>";
		out += "</tr>";
		cart.forEach(elem => {
			switch (elem.type) {
				case "el taco":
					out += "<tr><td style='text-align: left; width: 50%;'>El Taco holster<br>" + elem.make + " " + elem.model + "</td><td style='text-align: left; width: 50%;'>" + elem.qty + "</td><tr>";
					break;
				case "bracelet":
					let bracelet = "Bracelet<br>" + elem.size.size + "<br>Base Color: " + elem.base.label + "<br>Strip 1: " + elem.color1.label + "<br>Strip 2: " + elem.color2.label + "<br>Strip 3: " + elem.color3.label;
					out += "<tr><td style='text-align: left; width: 50%;'>" + bracelet + "</td><td style='text-align: left; width: 50%;'>" + elem.qty + "</td><tr>";
					break;
				case "little friend":
					out += "<tr><td style='text-align: left; width: 50%;'>Little Friend trigger guard<br>" + elem.make + " " + elem.model + "</td><td style='text-align: left; width: 50%;'>" + elem.qty + "</td><tr>";
					break;
				case "tshirt":
					out += "<tr><td style='text-align: left; width: 50%;'>" + elem.name + " - " + elem.size.size + "</td><td style='text-align: left; width: 50%;'>" + elem.qty + "</td><tr>";
					break;
				case "accessory":
					out += "<tr><td style='text-align: left; width: 50%;'>" + elem.name + "</td><td style='text-align: left; width: 50%;'>" + elem.qty + "</td><tr>";
					break;
				default:
					out += "<tr><td style='text-align: left; width: 50%;'>" + elem.name + "</td><td style='text-align: left; width: 50%;'>" + elem.qty + "</td><tr>";
					break;
			}
		})
		out += "</table>"
		return out;
	}
}