
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    constructor(
        private db: AngularFirestore
    ) {
        
    }
    getAdmin() {
        return this.db.collection<any>('admin').doc("1").valueChanges();
    }

    putAdmin( payload) {
        return this.db.collection('admin').doc("1").set(payload);
    }
}