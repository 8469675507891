<section class="product-details section" style="margin-top: 100px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <ng-container [ngSwitch]="mode">
                    <!-- password reset -->
                    <ng-container *ngSwitchCase="'resetPassword'">
                        <div class="single-product-content">
                            <h2>Reset password</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <p>Please enter and confirm your new password</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <label class="label">Password</label><br>
                                <input pInputText type='password' [(ngModel)]="password" class='form-control'>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <label class="label">Confirm Password</label><br>
                                <input pInputText type='password' [(ngModel)]="confirm" class='form-control'>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <button pButton label="Reset Password" (click)='resetPassword()' class='btn1'></button>
                            </div>
                        </div>
                        <div class="col-lg-12">

                        </div>

                    </ng-container>

                    <!-- verify email address -->
                    <ng-container *ngSwitchCase="'verifyEmail'">
                        <app-confirm-email-address></app-confirm-email-address>
                    </ng-container>

                    <!-- default action -->
                    <ng-container *ngSwitchDefault>
                        <!—show an error message -->
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</section>