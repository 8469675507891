import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth.component';

import { TooltipModule } from 'primeng/tooltip';
import { SystemService } from 'src/app/services/system.service';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

const routes: Routes = [
	{ path: 'auth', component: AuthComponent }
]

@NgModule({
	declarations: [
		AuthComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		TooltipModule,
        ButtonModule,
        InputTextModule,
		RouterModule.forChild(routes),
	],
	providers: [SystemService],
	exports: [AuthComponent]
})
export class AuthModule { }
