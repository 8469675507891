import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth/";
import { AngularFirestore, } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class UsersService {

	constructor(
		private db: AngularFirestore,

	) {

	}

	getUsers() {
		return this.db.collection<any>('profile').valueChanges()
	}
}