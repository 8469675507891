import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AngularFireAuth } from "@angular/fire/auth/";
import { AngularFirestore} from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root'
})
export class OrderLookupService {

	constructor(
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private db: AngularFirestore
	) {

	}

	getOrders(id) {
		return this.db.collection<any>('orders').doc(id).valueChanges();
	}
}