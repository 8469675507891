import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import * as pistols from 'src/assets/json/pistols.json';

@Component({
	selector: 'app-pistols',
	templateUrl: './pistols.component.html',
})
export class AdminPistolsComponent implements OnInit {
	loading: boolean = false;

	manufacturer: any[] = (pistols as any).default['pistols'];
	pistols: any = [];
	cols: any[] = [
		{ field: "make", header: "Make" },
		{ field: "model", header: "Model" },
		{ field: "stock", header: "In Stock" },
	]
	constructor(
		private systemService: SystemService,
		private messageService: MessageService
	) {

	}

	ngOnInit(): void {
		this.getData();

	}

	getData() {
		this.loading = true;
		this.manufacturer.forEach(elem => {
			let children = [];

			elem.models.forEach(model => {
				children.push({ data: { make: elem.label, model: model.label, instock: false } })
			});
			let node = { data: { make: elem.label, model: children.length }, children: children };
			this.pistols.push(node);

		})

		this.systemService.getPistols().subscribe(res => {
			console.log(res);
		})
		// this.manufacturer.forEach(elem => {
		// 	let payload = null;
		// 	elem.models.forEach(model => {
		// 		payload = { model: model.label, instock: false };
		// 		this.systemService.putProducts(elem.label, payload);
		// 	});
		// })
		this.loading = false;
	}
}
