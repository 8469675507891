import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ProductsService } from 'src/app/services/products.service';
import { AdminService } from '../../admin/admin/admin.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
	admin: any = { banner: "", hiring: "", hiringToggle: { name: "" } };
	loading: boolean = true;
	notifyMeEmail: string = "";

	constructor(
		private adminService: AdminService,
		private cd: ChangeDetectorRef,
		private productsService: ProductsService,
		private messageService: MessageService,
		private http: HttpClient
	) {

	}

	ngOnInit(): void {
		this.getData();
	}

	ngOnDestroy(): void {

	}

	getData() {
		this.loading = true;
		this.adminService.getAdmin().subscribe(res => {
			console.log(res);
			this.admin = res;
			this.loading = false;

			this.cd.detectChanges();
		})
	}

	notifyMe() {
		let payload = {
			message: "*Notify me about El Taco* - " + this.notifyMeEmail
		}
		this.productsService.notifyMe("el_taco", this.notifyMeEmail);

		this.http.post<any>(
			'https://us-central1-holsters-fc1c6.cloudfunctions.net/notifyDiscord',
			payload,
			{ headers: { "Authorization": "bearer $(gcloud auth print-identity-token)" } }).subscribe(res => {
				console.log(res);
			});
		this.notifyMeEmail = "";

		this.messageService.add({ key: "general", severity: 'success', summary: 'Thanks!', detail: "We'll notify you as soon as El Taco is ready" });
	}
}
