import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import { OrderService } from './orders.service';
@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
})
export class OrdersComponent implements OnInit {
	orders: any[] = [];
	loading: boolean = true;
	states: any[] = [
		{ label: "Order Received" },
		{ label: "Processing" },
		{ label: "Shipped" },
	]
	loadingShipping: boolean = false;
	rates: any[] = [];
	selectedOrder: any;
	loadingLabel: boolean = false;

	package: any = {
		length: '',
		width: '',
		height: '',
		weight: ''
	}
	dummyRow = [{ abc: 123 }]
	constructor(
		private systemService: SystemService,
		private orderService: OrderService,
		private http: HttpClient,
		private confirmationService: ConfirmationService
	) {

	}

	ngOnInit(): void {
		this.getData();
	}

	getData() {
		this.orderService.getOrders().subscribe(res => {
			this.loading = false;
			this.orders = res;
			console.log(this.orders);
		})
	}

	deleteOrder(order) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to delete this order?',
			accept: () => {
				//Actual logic to perform a confirmation
				this.orderService.deleteOrder(order.id);
				this.getData();
			}
		});
	}
	updateStatus(order) {

	}

	clearRates(order) {
		this.rates = [];
		this.package = {
			length: '',
			width: '',
			height: '',
			weight: ''
		};
		this.selectedOrder = order;
		console.log("selected Order", this.selectedOrder);
	}

	getRates() {
		this.loadingShipping = true;
		this.rates = [];
		let payload = {
			length: 8,
			width: 8,
			height: 2,
			weight: 1,
			shipping_address_components: this.selectedOrder.shipping_components,
			name: this.selectedOrder.name,
			email: this.selectedOrder.email
		}

		return this.http.post<any>('https://us-central1-holsters-fc1c6.cloudfunctions.net/getShippingRates', payload).subscribe(res => {
			console.log(res);
			this.loadingShipping = false;
			res.shipment.rates.forEach(rate => {
				if(rate.amount < 9) {this.rates.push(rate)}
			});
			// this.rates = res.shipment.rates;
		});
	}

	createLabel(rate) {
		this.loadingLabel = true;
		let payload = {
			rate: rate,
		}
		return this.http.post<any>('https://us-central1-holsters-fc1c6.cloudfunctions.net/createShippingLabel', payload).subscribe(res => {
			this.loadingLabel = false;
			console.log(res);
			this.selectedOrder.label_url = res.transacation.label_url;
			this.selectedOrder.tracking_url = res.transacation.tracking_url_provider;
			this.selectedOrder.status = 'Shipped';
			window.open(this.selectedOrder.label_url);
			this.orderService.updateOrder(this.selectedOrder, this.selectedOrder.id);
		});
	}
}
