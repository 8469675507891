import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
// import { FieldValue } from '@google-cloud/firestore';

@Injectable({
	providedIn: 'root'
})
export class SystemService {
	cartUpdated = new Subject();
	loginUpdated = new Subject();

	constructor(
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private db: AngularFirestore,
		private http: HttpClient,
		private router: Router,
		private messageService: MessageService
	) {

	}

	Login(email, password) {
		return this.afAuth.auth.signInWithEmailAndPassword(email, password)
			.then((result) => {
				console.log(result);
				this.funcSetAuthData(result.user);
				this.loginUpdated.next({ success: true });
			}).catch((error) => {
				console.log(error);
				this.messageService.add({ key: "general", severity: 'error', summary: 'System', detail: error.message });
				this.loginUpdated.next({ success: false, message: error.message });

			})
	}

	Register(email, password) {
		return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
			.then(async (result) => {
				console.log(result);
				sessionStorage.setItem('uid', result.user.uid);
				sessionStorage.setItem('email', result.user.email);
				let payload = { email: result.user.email };
				this.setProfile(payload);

				let email = {
					to: 'admin@holsters.io',
					from: 'admin@holsters.io',
					subject: 'new user',
					text: 'new user',
					html: 'New user!<br>email: ' + result.user.email
				};
				this.sendEmail(email).subscribe(res => {

				})
				this.funcSetAuthData(result.user);
				this.loginUpdated.next({ success: true });
			}).catch((error) => {
				// console.log(error);
				this.messageService.add({ key: "general", severity: 'error', summary: 'System', detail: error.message });
				this.loginUpdated.next({ success: false, message: error.message });
			})
	}

	public firebaseLogin(email, password) {

	}

	funcSetAuthData(data) {
		sessionStorage.setItem("uid", data.uid);
		sessionStorage.setItem("email", data.email);
	}
	funcCheckAuth() {
		if (!sessionStorage.getItem('uid')) { return false; }
		if ((sessionStorage.getItem('uid')) && (sessionStorage.getItem('email'))) { return true; } return false;
	}


	getProfile() {
		let uid = null;
		if (!sessionStorage.getItem('uid')) { return; }

		if (sessionStorage.getItem('uid')) { uid = sessionStorage.getItem('uid') };
		return this.getProfileService(uid);
	}

	putProfile(payload) {
		if (!sessionStorage.getItem('uid')) { return; }
		return this.db.collection('profile').doc(sessionStorage.getItem('uid')).update(payload);
	}

	setProfile(payload) {
		if (!sessionStorage.getItem('uid')) { return; }
		return this.db.collection('profile').doc(sessionStorage.getItem('uid')).set(payload);
	}

	putNewsletter(payload) {
		if (!sessionStorage.getItem('uid')) { return; }
		return this.db.collection('newsletter').doc(sessionStorage.getItem('uid')).set(payload);
	}

	// putPistols(doc, payload) {
	// 	return this.db.collection('pistols').doc(doc).collection(doc).add(payload);
	// }

	getPistols() {
		return this.db.collection<any>('pistols').doc('Beretta').collection('Beretta').valueChanges();
	}

	getProfileService(uid?) {
		if (!sessionStorage.getItem('uid')) { return; }

		if (sessionStorage.getItem('uid')) { uid = sessionStorage.getItem('uid') };
		return this.db.collection<any>('profile').doc(uid).valueChanges();
	}

	getNewsletter(uid?) {
		if (!sessionStorage.getItem('uid')) { return; }
		if (sessionStorage.getItem('uid')) { uid = sessionStorage.getItem('uid') };
		return this.db.collection<any>('newsletter').doc(uid).valueChanges();
	}

	sendEmail(payload) {
		return this.http.post("https://us-central1-holsters-fc1c6.cloudfunctions.net/sendemail", payload);
	}

	sendEmailReceipt(payload) {
		return this.http.post("https://us-central1-holsters-fc1c6.cloudfunctions.net/sendEmailReceipt", payload);
	}

	saveCartToStorage(cart) {
		localStorage.setItem('cart', JSON.stringify(cart));
	}

	getCartFromStorage() {
		return;
	}

	reloadCurrentRoute() {
		let currentUrl = this.router.url;
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { this.router.navigate([currentUrl]); });
	}

	async updateStats(dte, type, make, model, qty) {

		const ref = this.db.collection('stats_holsters').doc("day").ref;
		//.collection(make).doc(model).ref;
		this.db.firestore.runTransaction(transaction =>
			// This code may get re-run multiple times if there are conflicts.
			transaction.get(ref)
				.then(sfDoc => {
					console.log(sfDoc.data());
					// const newPopulation = sfDoc.data().tot + 1;
					if (sfDoc.exists) {
						transaction.update(ref, {
							make: make,
							model: model,
							tot: sfDoc.data().tot + qty
						});
					} else {
						ref.set({
							make: make,
							model: model,
							tot: qty
						});
					}
				})).then(() => console.log("Transaction successfully committed!"))
			.catch(error => console.log("Transaction failed: ", error));
	}

	resetPassword(code, password) {
		return this.afAuth.auth
			.confirmPasswordReset(code, password)
			.then(() => this.router.navigate(['login']))
			.catch(err => {
				this.messageService.add({ key: "general", severity: 'error', summary: 'System', detail: err.message });

			});
	}

	notifyDiscord(msg) {
		let msg_out = "";
		if (!environment.production) { msg_out = "*---------------TESTING---------------*\n" };
		msg_out += msg;
		let payload = {
			message: msg_out
		}
		this.http.post<any>(
			'https://us-central1-holsters-fc1c6.cloudfunctions.net/notifyDiscord',
			payload,
			{ headers: { "Authorization": "bearer $(gcloud auth print-identity-token)" } }).subscribe(res => {
				console.log(res);
			});
	}




}