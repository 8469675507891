import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ColorComponent } from './color.component';

import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';

import {OverlayPanelModule} from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';

@NgModule({
	declarations: [
		ColorComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		TooltipModule,
        OverlayPanelModule,
        ButtonModule,
	],
	exports: [ColorComponent]
})
export class ColorModule { }
