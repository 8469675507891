<app-admin-header></app-admin-header>
<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Products</h2>
				</div>
			</div>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
				<p-table #dt *ngIf="!loading" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
					responsiveLayout="scroll"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
					[rowsPerPageOptions]="[10,25,50]" [value]="products" dataKey="product_id" responsiveLayout="scroll"
					(onRowReorder)="reOrder($event)">
					<ng-template pTemplate="header">
						<tr>
							<th style="width:3rem"></th>
							<th style="width: 3rem"></th>
							<th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
								<p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
							</th>
							<th pSortableColumn="dtetme">Price <p-sortIcon field="dtetme"></p-sortIcon>
								<p-columnFilter type="date" field="dtetme" display="menu"></p-columnFilter>
							</th>
							<th pSortableColumn="status">Type <p-sortIcon field="status"></p-sortIcon></th>
							<th pSortableColumn="status">In Stock <p-sortIcon field="status"></p-sortIcon></th>
							<th pSortableColumn="status">Active <p-sortIcon field="status"></p-sortIcon></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-product let-index="rowIndex" let-expanded="expanded">
						<tr [pReorderableRow]="index">
							<td>
								<button type="button" pButton pRipple [pRowToggler]="product"
									class="p-button-text p-button-rounded p-button-plain"
									[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
							</td>
							<td>
								<span class="pi pi-bars" pReorderableRowHandle></span>
							</td>
							<td>
								{{product.name}}
							</td>
							<td>
								{{product.price}}
							</td>
							<td>{{product.type}}</td>
							<td>
								<p-checkbox [(ngModel)]="product.in_stock" [binary]="true" (onChange)="updateStock(product)"></p-checkbox>
							</td>
							<td>
								<p-checkbox [(ngModel)]="product.active" [binary]="true" (onChange)="updateActive(product)"></p-checkbox>
							</td>

						</tr>
					</ng-template>
					<ng-template pTemplate="rowexpansion" let-details>
						<tr>
							<td colspan="5">
							</td>
						</tr>
					</ng-template>

					<ng-template *ngIf="!products.length" pTemplate="summary">
						No products found.
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</section>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-overlayPanel #shipping appendTo="body">
	<ng-template pTemplate>
		<p-table [value]="dummyRow">
			<ng-template pTemplate="header">
				<tr>
					<th>Length</th>
					<th>Width</th>
					<th>Height</th>
					<th>Weight</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template let-row pTemplate="body">
				<tr>
					<td><input pInputText [(ngModel)]="package.length" placeholder="Length"></td>
					<td><input pInputText [(ngModel)]="package.width" placeholder="Width"></td>
					<td><input pInputText [(ngModel)]="package.height" placeholder="Height"></td>
					<td><input pInputText [(ngModel)]="package.weight" placeholder="Weight"></td>
					<td><button pButton (click)="getRates()">Get Rates</button></td>
				</tr>
			</ng-template>
		</p-table>

		<p-progressBar *ngIf="loadingShipping" mode="indeterminate"></p-progressBar>
		<p-table #dt *ngIf="rates && rates.length && !loadingShipping" responsiveLayout="scroll" [value]="rates">
			<ng-template pTemplate="header">
				<tr>
					<th>Carrier</th>
					<th>Rate</th>
					<th>Estimated</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rate let-ri="rowIndex" let-expanded="expanded">
				<tr>
					<td>{{rate.provider}}</td>
					<td>${{rate.amount}}</td>
					<td>{{rate.estimated_days}} days</td>
					<td>
						<span class="p-buttonset" style="white-space: nowrap;">
							<button pButton pTooltip="Print Label" [loading]="loadingLabel"
								(click)='createLabel(rate)'><i class='pi pi-print'></i></button>
						</span>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
</p-overlayPanel>