<header>
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-2 col-sm-6 col-xs-6">
				<div class="logo-area">
					<a href="/home" text="home"><img src="assets/images/logo/logo.png" alt="" /></a>
				</div>
			</div>
			<div class="col-md-10 col-sm-6 hidden-xs d-md-block d-xs-none d-none">
				<div class="main-header">
					<div class="main-menus">
						<nav>
							<ul class="mamnu">
								<li><a href="/home">Home</a></li>
								<li>
									<a>Products</a>
									<ul>
										<li><a href="/el-taco">El Taco Holster</a></li>
										<li><a href="/little_friend">Little Friend Trigger Guard</a></li>
										<li><a href="/bracelets">Bracelets</a></li>
										<li><a href="/gear">Gear</a></li>
									</ul>
								</li>
								<li><a href="/contact">Contact</a></li>
								<li><a href="/about">About</a></li>
							</ul>

						</nav>
					</div>

					<div class="cart-head">
						<button pTooltip='View Cart' (click)="gotoCart();"><i class="pi pi-shopping-cart" pBadge [value]="tot ? tot : 0" style="color: white; font-size: 24px;"></i></button>
						<a href="https://www.facebook.com/profile.php?id=100087305166071" pTooltip="Facebook" target="_blank" style="margin-left: 25px;"><i class='pi pi-facebook' style="font-size: 22px;"></i></a>
						<a href="https://www.linkedin.com/company/holsters-io/" pTooltip="LinkedIN" target="_blank" style="margin-left: 25px;"><i class='pi pi-linkedin' style="font-size: 22px;"></i></a>
						
					</div>
					<div class="sing-in-btn">
						<a href="/order-lookup" class="btn1">Order Lookup</a>
					</div>
				</div>
			</div>
			<div class="d-lg-none d-md-none d-sm-none col-xs-6 mobile_menu text-right" style="width: 50% !important;">
				<button type="button" pButton icon="pi pi-list" label="Menu" (click)="menu.toggle($event)"></button>
			</div>
		</div>
	</div>
</header>
<p-menu #menu [popup]="true" appendTo="body" [model]="menuItems"></p-menu>
