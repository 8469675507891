import { Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { MessageService } from 'primeng/api';

import { ProgressBarModule } from 'primeng/progressbar';
import { CommonModule } from '@angular/common';
import { AdminDashboardComponent } from './dashboard.component';
import { TableModule } from 'primeng/table';
import { DashboardService } from './dashboard.service';
import { AdminHeaderModule } from '../header/header.module';
import { TooltipModule } from 'primeng/tooltip';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardOrders {

    canActivate(route: ActivatedRouteSnapshot) {
        let success = false;
        let user = sessionStorage.getItem('uid');
        console.log("User", user);
        if (user == 'mQTD3KZTH2dbu6D7I3wFoCxWIg73') { success = true; }
        return success;
    }

}

const routes: Routes = [
    { path: 'admin/dashboard', component: AdminDashboardComponent, canActivate: [AuthGuardOrders] }
]

@NgModule({
    declarations: [
        AdminDashboardComponent
    ],
    imports: [
        FormsModule,
        ProgressBarModule,
        CommonModule,
        TableModule,
        AdminHeaderModule,
        FormsModule,
        TooltipModule,
        AdminHeaderModule,
        RouterModule.forChild(routes)

    ],
    providers: [MessageService, DashboardService],
    exports: [AdminDashboardComponent]
})
export class DashboardModule { }
