import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-order-shipping',
	templateUrl: './shipping.component.html',
})
export class OrderShippingComponent implements OnInit {
	@Input() details: any;

	constructor(
	) {

	}

	ngOnInit(): void {
		console.log(this.details)
	}

}
