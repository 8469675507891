
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpecialsService {
    constructor(
        private db: AngularFirestore
    ) {

    }
    getSpecials() {
        return this.db.collection<any>('specials', ref => ref.orderBy('order')).valueChanges({ idField: 'id' })
    }

    putSpecial(payload) {
        return this.db.collection('specials').add(payload);
    }

    updateSpecial(id, payload) {
        return this.db.collection('specials').doc(id).set(payload);
    }

    deleteSpecial(id: string) {
        return this.db
            .collection('specials')
            .doc(id)
            .delete();
    }
}