<app-admin-header></app-admin-header>
<section class="product-details section" style="margin-top: 100px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">

                <div style="background-color: white;">
                    <div *ngFor="let elem of pistols" style="display: block; clear: both;">{{elem.model}}</div>
                </div>
            </div>
        </div>
    </div>
</section>