import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';


import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import { PanelModule } from 'primeng/panel';
import { BraceletsComponent } from './bracelets.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ColorModule } from 'src/app/sub/color/color.module';

const routes: Routes = [
	{ path: 'bracelets', component: BraceletsComponent }
]

@NgModule({
	declarations: [
		BraceletsComponent,
	],
	imports: [
		PanelModule,
		CommonModule,
		FormsModule,
		TooltipModule,
		AccordionModule,
		RadioButtonModule,
		DropdownModule,
		CheckboxModule,
		InputTextModule,
		TableModule,
		InputTextareaModule,
		InputNumberModule,
		TabViewModule,
		ToastModule,
		ButtonModule,
		RouterModule.forChild(routes),
		ColorModule
	],
	providers: [MessageService, SystemService],
	exports: [BraceletsComponent]
})
export class BraceletModule { }
