
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class HoursService {
    constructor(
        private db: AngularFirestore
    ) {
        
    }
    getHours() {
        return this.db.collection<any>('hours').doc("1").valueChanges();
    }

    putHours( payload) {
        return this.db.collection('hours').doc("1").set(payload);
    }
}