import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';

import { NakedComponent } from './naked.component';

import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import { PanelModule } from 'primeng/panel';
import { ColorModule } from 'src/app/sub/color/color.module';

const routes: Routes = [
	{ path: 'little_friend', component: NakedComponent }
]

@NgModule({
	declarations: [
		NakedComponent,
	],
	imports: [
		PanelModule,
		CommonModule,
		FormsModule,
		TooltipModule,
		AccordionModule,
		RadioButtonModule,
		DropdownModule,
		CheckboxModule,
		TableModule,
		InputTextareaModule,
		InputNumberModule,
		TabViewModule,
		ToastModule,
		ColorModule,
		RouterModule.forChild(routes),
	],
	providers: [MessageService, SystemService],
	exports: [NakedComponent]
})
export class NakedModule { }
