import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import * as pistols from 'src/assets/json/pistols.json';
import * as paracord from 'src/assets/json/paracord.json';
import * as colors from 'src/assets/json/colors.json';

@Component({
	selector: 'app-naked',
	templateUrl: './naked.component.html',
})
export class NakedComponent implements OnInit {
	holster: any = {
		type: "little friend",
		color: "Tactical Black",
		base: { "label": "Black", "image": "/assets/images/paracord/black.jpg" },
		color1: { "label": "Red", "image": "/assets/images/paracord/red.jpg" },
		color2: { "label": "White", "image": "/assets/images/paracord/white.jpg" },
		color3: { "label": "Royal Blue", "image": "/assets/images/paracord/royal_blue.jpg" },
		price: 29.95,
		pistols: [{ make: "", model: "", qty: 1 }],
	}

	manufacturer: any[] = (pistols as any).default['pistols'];
	paracord: any[] = (paracord as any).default['colors'];
	colors: any[] = (colors as any).default['colors'];

	constructor(
		private messageService: MessageService,
		private systemService: SystemService
	) {

	}

	ngOnInit(): void {
		this.getData();
		console.log(this.manufacturer);
		let tot1 = 0;
		let tot2 = 0;
		this.manufacturer.forEach(man => {
			tot1++;
			man.models.forEach(make => {
				tot2++;

			});
		})
		console.log(tot1);
		console.log(tot2);
	}

	addPistol() {
		this.holster.pistols.push({ make: {}, model: "", qty: 1 })
	}

	getTotal() {
		let total = 0;
		this.holster.pistols.forEach(pistol => {
			let tmp = pistol.qty * this.holster.price;
			total = total + tmp;
		});
		return total.toFixed(2);
	}

	addToCart() {
		if ((!this.holster.pistols[0].make) && (!this.holster.pistols[0].model)) {
			this.messageService.add({ key: "general", severity: 'error', summary: 'Cart', detail: 'Please select a pistol make and model.' });
			return false;
		}
		let cart = [];
		if (localStorage.getItem('cart')) { cart = JSON.parse(localStorage.getItem('cart')) };
		this.holster.pistols.forEach(pistol => {
			let item = {
				type: "little friend",
				color: this.holster.color,
				base: this.holster.base,
				color1: this.holster.color1,
				color2: this.holster.color2,
				color3: this.holster.color3,
				price: this.holster.price,
				make: pistol.make.label,
				model: pistol.model,
				qty: pistol.qty
			}
			cart.push(item);

			let msg = "*Item added to cart*\n";
			msg += "**Little Friend - " + pistol.make.label + ": " + pistol.model + "**";
			this.systemService.notifyDiscord(msg);
		});
		this.systemService.saveCartToStorage(cart);
		this.messageService.add({ key: "create", severity: 'success', summary: 'Cart', detail: 'Holster added!' });
		this.systemService.cartUpdated.next("");
	}

	removePistol(index) {
		if (this.holster.pistols.length == 1) { return false; }
		this.holster.pistols.splice(index, 1);
		this.systemService.cartUpdated.next("");
	}

	removeColor(elem) {
		elem = this.paracord[0];
	}
	
	getData() {
	}
}
