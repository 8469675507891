<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Profile</h2>
					<p-progressBar *ngIf="loadingProfile" mode="indeterminate"></p-progressBar>

					<p-accordion *ngIf="!loadingProfile">
						<p-accordionTab header="Billing Information" [selected]="true">
							<p-progressBar *ngIf="loadingProfile" mode="indeterminate"></p-progressBar>
							<div class="row">
								<div class="col-lg-12">
									<label>Your Email</label><br>
									{{user.email}}
								</div>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<label>Your Name</label><br>
									<span (click)="user.name = ''" pTooltip="Click to change"
										*ngIf="user.name">{{user.name}}</span>
									<input *ngIf="!user.name" (focusout)="user.name = tmpUser.name; updateName()"
										(keyup.enter)="user.name = tmpUser.name; updateName()"
										[(ngModel)]="tmpUser.name" pInputText style='width: 100%;' />
								</div>
							</div>

							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<label>Company Name</label><br>
									<span (click)="user.company = ''" pTooltip="Click to change"
										*ngIf="user.company">{{user.company}}</span>
									<input *ngIf="!user.company"
										(focusout)="user.company = tmpUser.company; updateName()"
										[(ngModel)]="tmpUser.company" pInputText style='width: 100%;' />
								</div>
							</div>
							<app-address (callback)="saveBilling($event);" [user]="user"
								[address]="user.billing_address"
								[address_components]="user.billing_address_components"></app-address>

						</p-accordionTab>

						<p-accordionTab header="Shipping Information">
							<app-address (callback)="saveShipping($event);" [user]="user"
								[address]="user.shipping_address"
								[address_components]="user.shipping_address_components"></app-address>

						</p-accordionTab>
						<p-accordionTab header="Communications">
							<div class="row">
								<div class="col-lg-12">
									<p>We hate, HATE, spam email. No reminding you about items in your cart, price
										drops, etc... Choose what emails you want to receive below. Don't want any?
										Don't check any.</p>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-12">
									<p-checkbox name="newsletter" [binary]="true" label="New product announcements"
										[(ngModel)]="newsletter.products"></p-checkbox>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-12">
									<p-checkbox name="newsletter" [binary]="true" label="Price drops"
										[(ngModel)]="newsletter.price"></p-checkbox>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-12">
									<p-checkbox name="newsletter" [binary]="true" label="Holiday promotions"
										[(ngModel)]="newsletter.holiday"></p-checkbox>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-12">
									<button role="button" (click)="saveNewsletter()" class="btn1"
										style="float: right;">Save</button>
								</div>
							</div>
						</p-accordionTab>
					</p-accordion>
					<div class="row" style="margin-top: 20px;">
						<div class="col-lg-12">
							<button pButton class="btn1" (click)="logout()">Logout</button>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Orders</h2>
					<p></p>
				</div>
				<app-orders></app-orders>
			</div>
		</div>
	</div>
</section>