import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MessageService } from 'primeng/api';

import { ProgressBarModule } from 'primeng/progressbar';
import { CommonModule } from '@angular/common';
import { ApplicationsService } from './application.service';
import { AdminApplicationsComponent } from './application.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
const routes: Routes = [
  { path: 'admin/applications', component: AdminApplicationsComponent }
]

@NgModule({
  declarations: [
    AdminApplicationsComponent
  ],
  imports: [
    FormsModule,
    ProgressBarModule,
    CommonModule,
	TableModule,
	ButtonModule,
	TooltipModule,
	DialogModule,
	ConfirmDialogModule,
    RouterModule.forChild(routes)

  ],
  providers: [MessageService, ApplicationsService, ConfirmationService],
  exports: [AdminApplicationsComponent]
})
export class AdminApplicationsModule { }
