<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>About holsters.io</h2>
				</div>
			</div>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p>Founded in Clearwater, FL in 2022, we are a local holster and accessory making shop shipping nationally across the USA with the ability to customize all products and produce bulk orders. </p>
			</div>
		</div>
	</div>
</section>