import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth/";
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class ProductsService {


	constructor(
		private db: AngularFirestore,

	) {

	}

	putProduct(payload) {
		if (!sessionStorage.getItem('uid')) { return; }
		return this.db.collection('printful_products').add(payload);
	}

	getAllProducts() {
		return this.db.collection('printful_products', ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'product_id' });
	}
	
	getProducts() {
		return this.db.collection('printful_products', ref => ref.orderBy('order', 'asc').where("active", "==", true)).valueChanges({ idField: 'product_id' });
	}

	updateProduct(id, payload) {
		return this.db.collection('printful_products').doc(id).update(payload);
	}

	notifyMe(product, email) {
		return this.db.collection('out_of_stock').doc(product).collection('users').add({ email: email });
	}
}