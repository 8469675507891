import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SpecialsService } from '../../admin/specials/specials.service';

import { SpecialsComponent } from './specials.component';

@NgModule({
  declarations: [
    SpecialsComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers: [SpecialsService],
  exports: [SpecialsComponent]
})
export class SpecialsModule { }
