import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SystemService } from 'src/app/services/system.service';
import { CartService } from './cart.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { StripeService, StripePaymentElementComponent } from 'ngx-stripe';
import { StripeElementsOptions, PaymentIntent } from '@stripe/stripe-js';

import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BoxDimensionCalculatorService } from 'src/app/services/dimensions.service';
import * as countries from 'src/assets/json/countries.json';
import { markdownTable } from 'markdown-table'
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';


@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
})
export class CartComponent implements OnInit {
	cart = [];
	user: any = {
		email: "",
		name: "",
		password: "",
		billing_address: null,
		billing_address_components: null,
		shipping_address: null,
		shipping_address_components: null,
	}

	errors: any = {
		email: false,
		name: false,
	}

	tmpUser: any = {
		company: "",
		name: ""
	}
	activeIndex: number = 0;

	@ViewChild(StripePaymentElementComponent)
	paymentElement: StripePaymentElementComponent;
	amount: number = 0;

	countries: any[] = (countries as any).default['countries'];

	elementsOptions: StripeElementsOptions = {
		locale: 'en'
	};

	paying = false;
	loading: boolean = false;
	loadingVA: boolean = false;
	loadingPay: boolean = false;

	starterKit: boolean = false;

	vaDiscount = 0;
	subtotal: number = 0;

	// salestax: number = 0;
	total: number = 0;
	kitDiscount: number;
	shippingRates: any[] = [];
	// shipping: any;
	shippingItems: any[] = [];

	points: number = 0;
	points_total: number = 0;
	loadingTotal: boolean = false;
	done: boolean = false;

	constructor(
		private http: HttpClient,
		public systemService: SystemService,
		private cartService: CartService,
		private stripeService: StripeService,
		private router: Router,
		private messageService: MessageService,
		private boxDimCalc: BoxDimensionCalculatorService,
		private cd: ChangeDetectorRef,
		private analytics: AngularFireAnalytics
	) {

	}

	ngOnInit(): void {
		this.amount = 0;
		this.total = 0;
		this.subtotal = 0;
		// this.salestax = 0;
		// this.shipping = null;
		this.cart = [];
		this.loading = true;

		this.getData();

		// this.loggedIn = this.systemService.funcCheckAuth();

		// if (this.loggedIn) {
		// this.getProfile();
		// } else {
		// this.loading = false;
		// }

		// this.systemService.loginUpdated.subscribe(res => {
		// 	this.loggedIn = res['success'] ? true : false;
		// 	if (res['success']) {
		// 		console.log(res['message']);
		// 		this.getProfile();
		// 	} else {
		// 		this.loading = false;
		// 		this.user = { email: "", password: "", address: null }
		// 	}
		// })
	}

	getProfile() {
		this.cartService.getProfile().subscribe(res => {
			console.log(res);
			this.user.name = res['name'] ? res['name'] : "";
			this.tmpUser.name = this.user.name ? this.user.name : "";

			let tmp = this.user.name.split(" ");
			this.user.first_name = tmp[0];
			if (tmp.length > 2) {
				this.user.middle_name = tmp[1];
				this.user.last_name = tmp[2];
			} else {
				this.user.last_name = tmp[1];
			}

			this.user.company = res['company'] ? res['company'] : "";
			this.tmpUser.company = this.user.company ? this.user.company : "";

			this.user.email = res['email'];

			this.user.billing_address = res['billing_address'];
			this.user.billing_address_components = res['billing_address_components'];

			this.user.shipping_address = res['shipping_address'];
			this.user.shipping_address_components = res['shipping_address_components'];

			this.user.vaverified = res['veteran'];

			this.user.userid = sessionStorage.getItem('uid');

			if (this.user.shipping_address) {
				this.getData();
			} else {
				this.cart = JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : [];
				this.loading = false;
			}
		})
	}

	getData() {
		this.loading = true;
		this.points = 0;
		this.amount = 0;
		this.total = 0;
		this.subtotal = 0;
		// this.salestax = 0;
		// this.shipping = null;
		this.cart = [];
		this.cart = JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : [];
		console.log(this.cart);

		let total_shipping_items = null;
		if (this.cart.length) {
			let holsters = this.cart.filter(x => x.type == 'el taco');
			let lf = this.cart.filter(x => x.type == 'little friend');

			if (holsters.length && lf.length) {
				holsters.forEach(elem => {
					lf.forEach(el => {
						if ((elem.make == el.make) && (elem.model == el.model)) {
							el.price = 19.95;
							el.original_price = 29.95;
							el.discount_applied = true;
						}
					})
				})
			}

			let items = this.cart.filter(x => (x.type == 'el taco') || (x.type == 'little friend') || (x.type == 'bracelet'));
			if (items.length) { total_shipping_items = 8; }

			this.printfulShipping((tot) => {

				// this.shipping = total_shipping_items + Number(tot);
				this.cd.detectChanges();
				// console.log("Shipping", this.shipping);
				let count = 0;
				this.amount = 0;
				this.cart.forEach(item => {
					item.id = count;
					this.amount += (item.price * item.qty) * 100;
					count++;
				})

				this.subtotal = this.amount;

				if (this.user.vaverified == 'confirmed') {
					this.vaDiscount = this.amount * .2;
					let out = this.amount - this.vaDiscount;
					this.amount = out;
				} else { this.vaDiscount = null; }

				// this.salestax = (this.amount * .07);
				console.log("amount", this.amount);
				// console.log("salestax", this.salestax);

				// if (this.shipping) {
				this.total = this.amount;
				// this.total = (this.amount + this.salestax + (Number(this.shipping) * 100));
				// } else {
				this.total = this.amount;
				// this.total = (this.amount + this.salestax);

				// }

				if (this.total < 0) { this.total = 0; }
				this.loadingTotal = false;


			})
		} else {
			this.loading = false;
		}
	}

	saveBilling(data) {
		if (!this.user.email) { this.errors.email = true; return; } else { this.errors.email = false; }
		if (!this.user.name) { this.errors.name = true; return; } else { this.errors.name = false; }
		if (!data.address) { this.errors.address = true; return; } else { this.errors.address = false; }
		// this.systemService.putProfile({ billing_address: data.address, billing_address_components: data.address_components });
		// this.getProfile();
		this.user.billing_address = data.address;
		this.user.billing_address_components = data.address_components;

		this.createPaymentIntent(this.total, this.user).subscribe(pi => {
			this.loading = false;
			console.log(pi);
			this.elementsOptions.clientSecret = pi.client_secret;
		});

		this.activeIndex = 1;
		this.messageService.add({ key: "general", severity: 'success', summary: 'Cart', detail: 'Billing information saved' });

	}

	copyBilling() {
		this.user.shipping_address = this.user.billing_address;
		this.user.shipping_address_components = this.user.billing_address_components;
	}

	saveShipping(data) {
		// this.systemService.putProfile({ shipping_address: data.address, shipping_address_components: data.address_components }).finally(() => {
		// this.getProfile();
		// this.getData();
		if (!data.address) { this.errors.shipping = true; return; } else { this.errors.shipping = false; }

		this.user.shipping_address = data.address;
		this.user.shipping_address_components = data.address_components;
		this.activeIndex = 3;
		this.messageService.add({ key: "general", severity: 'success', summary: 'Cart', detail: 'Shipping information saved' });
		// });
	}

	updateName() {
		// this.systemService.putProfile({ name: this.user.name, company: this.user.company });
		// this.getProfile();
	}

	removePistol(index) {
		console.log(index);
		this.cart.splice(index, 1);
		this.systemService.saveCartToStorage(this.cart);
		this.systemService.cartUpdated.next("");

		this.getData();
	}

	notifyDiscord() {
		let msg = "*New Order*\n";
		if (!environment.production) { msg += "*---------------TESTING---------------*\n" };
		msg += "**Email** " + this.user.email + "\n";
		msg += "**Name** " + this.user.name + "\n";
		msg += "**Order Total: **$" + (this.total / 100).toFixed(2) + "\n```";

		markdownTable([
			['Branch', 'Commit'],
			['main', '0123456789abcdef'],
			['staging', 'fedcba9876543210']
		])

		let a = [
			['Type', 'Item', 'Size', 'Qty']
		]

		this.cart.forEach(elem => {
			switch (elem.type) {
				case "bracelet":
					a.push(['Bracelet', "", elem.size.size, elem.qty]);
					break;
				case "el taco":
					a.push(['El Taco', elem.make + " " + elem.model, "", elem.qty]);
					break;
				case "little friend":
					a.push(['Little Friend', elem.make + " " + elem.model, "", elem.qty]);
					break;
				case "tshirt":
					a.push(['T-Shirt', elem.name, elem.size.size, elem.qty]);
					break;
				case "accessory":
					a.push(['Accessory', elem.name, "", elem.qty]);
					break;
			}
		})
		msg += markdownTable(a);
		msg += "```";
		let payload = {
			message: msg
		}
		this.http.post<any>(
			'https://us-central1-holsters-fc1c6.cloudfunctions.net/notifyDiscord',
			payload,
			{ headers: { "Authorization": "bearer $(gcloud auth print-identity-token)" } }).subscribe(res => {
				console.log(res);
			});
	}

	finishPayment() {
		this.processOrder();
	}

	collectPayment() {
		if (this.paying) return;
		this.loadingPay = true;
		this.paying = true;
		this.stripeService
			.confirmPayment({
				elements: this.paymentElement.elements,
				confirmParams: {
					payment_method_data: {
						billing_details: {
							name: this.user.name,
							email: this.user.email
						},
					},
				},
				redirect: 'if_required',
			})
			.subscribe({
				next: (result) => {
					if (result.error) {
						this.loadingPay = false;
						this.messageService.add({ key: "general", severity: 'error', summary: 'Cart', detail: 'We had trouble processing your order.Please try again.' + result.error.message }); return false;
					} else if (result.paymentIntent.status === 'succeeded') {

						let points = 0;
						this.cart.forEach(elem => {
							points += elem.points_used;
						})
						console.log("points total", this.points_total);

						let out = this.points_total - (points ? points : 0);
						console.log(out);
						out += this.points;
						console.log(out);

						let payload = {
							points: out
						}
						// this.systemService.putProfile(payload);

						this.processOrder();
					}
				},
				error: (err) => {
					this.loadingPay = false;
					this.messageService.add({ key: "general", severity: 'error', summary: 'Cart', detail: 'We had trouble processing your order.Please try again.' }); return false;
				},
			});
	}

	processOrder() {
		this.notifyDiscord();
		this.analytics.logEvent('checkout');
		this.analytics.setUserId(this.user.email);
		this.analytics.setUserProperties({ total: (this.total / 100).toFixed(2) });
		this.paying = false;
		console.log(this.cart);
		const today = new Date();
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();
		let ddd: string = "", mmm: string = "";
		if (dd < 10) { ddd = '0' + dd; } else { ddd = dd.toString(); }
		if (mm < 10) { mmm = '0' + mm; } else { mmm = mm.toString(); }

		const formattedToday = ddd + '-' + mmm + '-' + yyyy;

		// this.cart.forEach(elem => {
		// this.systemService.updateStats(formattedToday, elem.type, elem.make ? elem.make : elem.name, elem.model, elem.qty);
		// })

		let printful = this.cart.indexOf(x => (x.type == 'accessory') || (x.type == 'tshirt'));
		if (printful) {
			let items = this.cart.filter(x => (x.type == 'accessory') || (x.type == 'tshirt'));
			if (items.length) {
				let itemsPayload = [];
				items.forEach(elem => {
					switch (elem.type) {
						case 'tshirt':
							itemsPayload.push({
								"external_variant_id": elem.size.external_variant_id,
								"quantity": Number(elem.qty)
							})
							break;
						case 'accessory':
							itemsPayload.push({
								"external_variant_id": elem.external_variant_id,
								"quantity": Number(elem.qty)
							})
							break;
					}

				})
				let printfulPayload = {
					name: this.user.name,
					address1: this.user.shipping_address_components.address1,
					address2: this.user.shipping_address_components.address2,
					city: this.user.shipping_address_components.city,
					state_code: this.user.shipping_address_components.state,
					zip: this.user.shipping_address_components.postal,
					country_code: this.user.shipping_address_components.country,
					items: itemsPayload
				}
				console.log(printfulPayload);
				this.http.post<any>(
					'https://us-central1-holsters-fc1c6.cloudfunctions.net/printFulSubmitOrder',
					printfulPayload,
					{ headers: { "Authorization": "bearer $(gcloud auth print-identity-token)" } }).subscribe(res => {
						console.log(res);
					});
			}
		}

		this.loadingPay = false;
		let payload = {
			amount: (this.total / 100).toFixed(2),
			subtotal: (this.subtotal / 100).toFixed(2),
			// shippingTot: (this.shipping).toFixed(2),
			discount: (this.vaDiscount / 100).toFixed(2),
			// tax: (this.salestax / 100).toFixed(2),
			name: this.user.name,
			email: this.user.email,
			company: this.user.company ? this.user.company : "",
			shipping: this.user.shipping_address,
			shipping_components: this.user.shipping_address_components,
			// shipping_details: this.shipping,
			cart: this.cart,
			dtetme: new Date().toString(),
			status: "Order Received",
			userid: sessionStorage.getItem('uid')
		}
		console.log(payload);
		let uid = "";
		this.cartService.putOrder(payload).then(res => {
			console.log(res);
			uid = res.uid;
			localStorage.removeItem('cart');
			this.router.navigate(['/account']);
			this.done = true;
			this.messageService.add({ key: "general", severity: 'success', summary: 'Cart', detail: 'Payment processed successfully' });


			let email = {
				to: this.user.email,
				subject: "holsters.io receipt",
				"order_number": uid,
				"full_name": this.user.name,
				"address": this.user.billing_address,
				"subtotal": (this.subtotal / 100).toFixed(2),
				// "shipping": (this.shipping).toFixed(2),
				"discount": this.user.vaverified == 'confirmed' ? (this.vaDiscount / 100).toFixed(2) : null,
				// "tax": (this.salestax / 100).toFixed(2),
				"total": (this.total / 100).toFixed(2),
				"items": this.cartService.genEmail(this.cart)
			}
			console.log(email);
			this.systemService.sendEmailReceipt(email).subscribe(res => {
			});

			let adminEmail = email;
			adminEmail.to = 'admin@holsters.io';

			this.systemService.sendEmailReceipt(adminEmail).subscribe(res => {
			});
			localStorage.removeItem('cart');
			this.systemService.cartUpdated.next("");
		})
		console.log('Payment processed successfully');
	}

	private createPaymentIntent(amount: number, user): Observable<PaymentIntent> {
		console.log(user);
		return this.http.post<PaymentIntent>(
			'https://us-central1-holsters-fc1c6.cloudfunctions.net/charge',
			{ amount: amount, email: user.email, addr: user.billing_address, shipping: user.shipping_address },
			{ headers: { "Authorization": "bearer $(gcloud auth print-identity-token)" } }
		);
	}

	verifyVA() {
		console.log("User", this.user);
		if (!this.user.first_name) { this.messageService.add({ key: "general", severity: 'error', summary: 'Veteran Verification', detail: 'Please enter your first name' }); return false; }
		if (!this.user.last_name) { this.messageService.add({ key: "general", severity: 'error', summary: 'Veteran Verification', detail: 'Please enter your last name' }); return false; }
		if (!this.user.ssnum) { this.messageService.add({ key: "general", severity: 'error', summary: 'Veteran Verification', detail: 'Please enter your SS#' }); return false; }
		if (!this.user.dob) { this.messageService.add({ key: "general", severity: 'error', summary: 'Veteran Verification', detail: 'Please enter your Date of Birth' }); return false; }
		this.loadingVA = true;
		let payload = {
			"ssn": this.user.ssnum,
			"first_name": this.user.first_name,
			"middle_name": this.user.middle_name,
			"last_name": this.user.last_name,
			"birth_date": this.user.dob,
		}
		this.http.post('https://api.va.gov/services/veteran_confirmation/v0/status', payload, { headers: { "apikey": "CCvuwFfg8MTagM9EKQbW0WH9wjUX1ObG" } }).subscribe(res => {
			console.log(res);
			if (res['veteran_status'] == 'confirmed') {
				this.user.vaverified = 'confirmed';
				this.getData();
				this.messageService.add({ key: "general", severity: 'success', summary: 'Veteran Verification', detail: 'The VA was able to verify your status!' })
			} else {
				this.messageService.add({ key: "general", severity: 'error', summary: 'Veteran Verification', detail: 'The VA was not able to verify your status. Please try again.' })
			}
			// this.systemService.putProfile({ veteran: res['veteran_status'] }).then(res => {
			// this.loadingVA = false;
			// console.log(res);
			// })
		})
	}

	updateQTY() {
		this.loadingTotal = true;
		this.systemService.saveCartToStorage(this.cart);
		this.getData();
	}

	calcShipping(): Observable<number> {
		let items = this.cart;
		let out = [];
		items.forEach(elem => {
			switch (elem.type) {
				case "el taco":
					for (let i = 0; i < elem.qty; i++) {
						out.push({ l: 6, w: 4, h: 2 });
					}
					break;
				case "little friend":
					for (let i = 0; i < elem.qty; i++) {
						out.push({ l: 4, w: 4, h: 2 });
					}
					break;
				case "bracelet":
					for (let i = 0; i < elem.qty; i++) {
						out.push({ l: 4, w: 4, h: 1 });
					}
					break;
			}
		})
		let calc = this.boxDimCalc.combineLoop(out)[0];
		console.log("dimensions: ", calc);
		if (calc) {
			let payload = {
				length: calc.l,
				width: calc.w,
				height: calc.h,
				weight: 3,
				shipping_address_components: this.user.shipping_address_components,
				name: this.user.name,
				email: this.user.email
			}
			console.log(payload);
			this.http.post<any>('https://us-central1-holsters-fc1c6.cloudfunctions.net/getShippingRates', payload).subscribe(res => {
				// console.log(res);
				this.shippingItems = [];
				this.shippingRates = res.shipment.rates;
				this.shippingRates.forEach(elem => {
					let out = {
						label: "$" + elem.amount + " " + elem.servicelevel.name + " " + elem.estimated_days + " day" + (elem.estimated_days > 1 ? "s" : ""),
						command: () => {
							// this.shipping = elem; 
							this.getData();
						}
					}
					this.shippingItems.push(out);
				})

				return this.shippingRates[1].amount;
			});
		}
		return null;
	}

	printfulShipping(cb): Observable<any> {
		this.loading = false;

		let printful = this.cart.filter(x => (x.type == 'accessory') || (x.type == 'tshirt'));
		if (!printful.length) {
			return cb(0);
		} else {
			let items = this.cart.filter(x => (x.type == 'accessory') || (x.type == 'tshirt'));
			if (items.length) {
				let itemsPayload = [];
				items.forEach(elem => {
					switch (elem.type) {
						case 'tshirt':
							itemsPayload.push({
								external_variant_id: elem.size.external_variant_id,
								quantity: Number(elem.qty)
							})
							break;
						case "accessory":
							itemsPayload.push({
								"external_variant_id": elem.external_variant_id,
								"quantity": Number(elem.qty)
							})
							break;
					}

				})
				let country_code = "US";
				if (this.user.shipping_address_components) {
					let country = this.countries.find(x => x.name == this.user.shipping_address_components.country);
					country_code = country ? country.code : "US";

					let printfulPayload = {
						name: this.user.name,
						address1: this.user.shipping_address_components.address1,
						city: this.user.shipping_address_components.city,
						state_code: this.user.shipping_address_components.state,
						zip: this.user.shipping_address_components.postal,
						country_code: country_code,
						items: itemsPayload
					}
					console.log(printfulPayload);
					this.http.post<any>(
						'https://us-central1-holsters-fc1c6.cloudfunctions.net/printFulShippingRate',
						printfulPayload,
						{ headers: { "Authorization": "bearer $(gcloud auth print-identity-token)" } }).subscribe(res => {
							console.log(res);
							return cb(res.body.result[0].rate);
						});
				} else {
					return cb(0);
				}

			}
		}
	}
}

