import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TrustHtmlModule} from 'trust-html-pipe';

import { HomeComponent } from './home.component';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ProductsService } from 'src/app/services/products.service';
import { MessageService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
const routes: Routes = [
	{ path: 'home', component: HomeComponent }
]

@NgModule({
	declarations: [
		HomeComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		TrustHtmlModule,
		InputTextModule,
		ButtonModule,
		HttpClientModule,
		RouterModule.forChild(routes),
	],
	providers: [ProductsService, MessageService],
	exports: [HomeComponent]
})
export class HomeModule { }
