import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { SystemService } from './services/system.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	auth: boolean = false;
	currentRoute: any;
	collapsed: boolean = false;

	constructor(
		private authService: AuthService,
		private router: Router,
		private messageService: MessageService,
		private systemService: SystemService
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				// Hide progress spinner or progress bar
				this.currentRoute = event.url;
				switch (this.currentRoute) {
					case "/home":
						this.collapsed = false;
						break;
					case "/":
						this.collapsed = false;
						break;

					default:
						this.collapsed = true;
						break;
				}
				console.log(event);
			}
		});
	}
	ngOnInit(): void {
		this.loadScript();
		this.systemService.loginUpdated.subscribe(res => {
			// console.log(res);
			// if (!res['success']) {
			// }
		})
	}

	addAnother() {
		this.messageService.clear("create");
		this.systemService.cartUpdated.next("");
		this.systemService.reloadCurrentRoute();
	}

	gotoCart() {
		this.messageService.clear("create");
		this.systemService.cartUpdated.next("");
		this.router.navigate(['/cart']);
	}

	loadScript() {
		
		let node = document.createElement('script'); // creates the script tag
		node.src = '//code.tidio.co/w00qrsgdcocbubnpaciogvfh0dqq1h9v.js'; // sets the source (insert url in between quotes)
		node.type = 'text/javascript'; // set the script type
		node.async = true; // makes script run asynchronously
		node.charset = 'utf-8';
		// append to head of document
		document.getElementsByTagName('head')[0].appendChild(node); 
	  }
}
