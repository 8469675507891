<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="single-product-content">
					<h2>1911 Grips</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
				<p-carousel [value]="grips" [numVisible]="2" [numScroll]="2">
					<ng-template let-item pTemplate="item">
						<p-carousel [value]="item.images" [numVisible]="1" [numScroll]="1">
							<ng-template let-img pTemplate="item">
								<img [src]="img.image" style="width: 1005;">
							</ng-template>
						</p-carousel>
						<div class="row" style="margin-top: 20px;">
							<div class="col-lg-12 text-center" style="font-size: 18px; color: #fff;">
								{{item.name}}
							</div>
						</div>
						<div class="row" style="margin-top: 20px;">
							<div class="col-lg-12 text-center" style="font-size: 14px; color: #fff;">
								${{item.price}}
							</div>
						</div>
						<div class="row" style="margin-top: 20px;">
							<div class="col-lg-12 text-center">
								<button (click)="Customize(item)" pButton class="btn1">Customize this grip</button>
							</div>
						</div>
					</ng-template>
				</p-carousel>
			</div>
			<div *ngIf="selectedGrip" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Customize your {{selectedGrip.name}}</h2>
					<p-accordion>

						<p-accordionTab [header]="'Color'">
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<div *ngFor="let color of colors" [pTooltip]="color.label" (click)="selectedGrip.color = color.label" [ngStyle]="{'background-color': color.color}" style="cursor: pointer; display: block; width: 50px; height: 50px; float: left;">
										<i *ngIf="selectedGrip.color == color.label" class="pi pi-check-circle" style="margin-left: 12px; margin-top: 14px; font-size: 24px; color: green;"></i>
									</div>
								</div>
							</div>
						</p-accordionTab>

						<p-accordionTab [header]="'Infill color'">
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<div *ngFor="let color of colors" [pTooltip]="color.label" (click)="selectedGrip.infill = color.label" [ngStyle]="{'background-color': color.color}" style="cursor: pointer; display: block; width: 50px; height: 50px; float: left;">
										<i *ngIf="selectedGrip.infill == color.label" class="pi pi-check-circle" style="margin-left: 12px; margin-top: 14px; font-size: 24px; color: green;"></i>
									</div>
								</div>
							</div>
						</p-accordionTab>

					</p-accordion>
					<!-- {{holster | json}} -->
					<div class="select-pro">
						<div class="buttons">
							<button role="button" (click)="addToCart()" class="btn1">Add to cart</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row section5">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p-tabView>
					<p-tabPanel header="About our holsters">
						Our holsters are crafted in Tampa, FL and hand made following your exact specifications above. We only use .080 Kydex and the finest felt liner. All holsters are made to order and made 1 at a time. Our holsters are also 1 flat price of $80 each. We don't nickle and dime our customers for images, buckles or other modifications.
					</p-tabPanel>
					<p-tabPanel header="FAQ">
						Content 2
					</p-tabPanel>
					<p-tabPanel header="Warrenty">
						Your holsters.io holster has a lifetime warrenty. Simply send it back and we'll replace it for free.
					</p-tabPanel>
					<p-tabPanel header="Need something else?">
						We're down to make something even more custom than what's listed above. Need a pistol not listed? Specific color? Bulk order? Message us and let's talk!

					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</div>
</section>