import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-product-details',
	templateUrl: './details.component.html',
})
export class ProductDetailsComponent implements OnInit {
	@Input() details: any;
	@Input() fullwidth: boolean = false;
	constructor(
	) {

	}

	ngOnInit(): void {
		console.log(this.details)
	}

}
