<form [formGroup]="myForm" (ngSubmit)="postApplication(myForm)">

	<div class="p-grid" style="margin: 20px;">
		<div class="p-col-12">
			<h3>Job Application</h3>
			<p>{{admin.hiring}}</p>
			<div class="p-grid">
				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Name</label>
					<input type="text" pInputText formControlName="name">
					<small *ngIf="myForm.get('name').invalid && (myForm.get('name').dirty || myForm.get('name').touched)" class="p-invalid">This field is required</small>
				</div>
				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Date of Birth</label>
					<p-calendar formControlName="dob" dateFormat="mm.dd.yy"></p-calendar>
					<small *ngIf="myForm.get('dob').invalid && (myForm.get('dob').dirty || myForm.get('dob').touched)" class="p-invalid">This field is required</small>

				</div>
				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Email Address</label>
					<input type="text" pInputText formControlName="email">
					<small *ngIf="myForm.get('email').invalid && (myForm.get('email').dirty || myForm.get('email').touched)" class="p-invalid">This field is required</small>
				</div>
			</div>
			<div class="p-grid">
				<div class='p-col-12'>
					<label>Address line 1</label>
					<input type="text" pInputText formControlName="address1">
					<small *ngIf="myForm.get('address1').invalid && (myForm.get('address1').dirty || myForm.get('address1').touched)" class="p-invalid">This field is required</small>

				</div>
			</div>
			<div class="p-grid">
				<div class='p-col-12'>
					<label>Address line 2</label>
					<input type="text" formControlName="address2" pInputText>
				</div>
			</div>
			<div class="p-grid">
				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>City</label>
					<input type="text" pInputText formControlName="city">
					<small *ngIf="myForm.get('city').invalid && (myForm.get('city').dirty || myForm.get('city').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Zipcode</label>
					<input type="text" pInputText formControlName="zipcode">
					<small *ngIf="myForm.get('zipcode').invalid && (myForm.get('zipcode').dirty || myForm.get('zipcode').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Phone</label>
					<input type="text" pInputText formControlName="phone">
					<small *ngIf="myForm.get('phone').invalid && (myForm.get('phone').dirty || myForm.get('phone').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Position you are applying for</label>
					<input type="text" pInputText formControlName="position">
					<small *ngIf="myForm.get('position').invalid && (myForm.get('position').dirty || myForm.get('position').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Do you have the right to work in the U.S.A.</label>
					<p-selectButton [options]="options" formControlName="citizen" optionLabel="name"></p-selectButton>
					<small *ngIf="myForm.get('citizen').invalid && (myForm.get('citizen').dirty || myForm.get('citizen').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Are you over the age of 18</label>
					<p-selectButton [options]="options" formControlName="age" optionLabel="name"></p-selectButton>
					<small *ngIf="myForm.get('age').invalid && (myForm.get('age').dirty || myForm.get('age').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Have you been convicted of a crime (not including infractions)</label>
					<p-selectButton [options]="options" formControlName="crime" optionLabel="name"></p-selectButton>
					<small *ngIf="myForm.get('crime').invalid && (myForm.get('crime').dirty || myForm.get('crime').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Requested start date</label>
					<p-calendar formControlName="startdte" dateFormat="mm.dd.yy"></p-calendar>
					<small *ngIf="myForm.get('startdte').invalid && (myForm.get('startdte').dirty || myForm.get('startdte').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>High School or Trade School</label>
					<input type="text" pInputText formControlName="highschool">
					<small *ngIf="myForm.get('highschool').invalid && (myForm.get('highschool').dirty || myForm.get('highschool').touched)" class="p-invalid">This field is required</small>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>College</label>
					<input type="text" pInputText formControlName="college">
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Military Service</label>
					<input type="text" pInputText formControlName="military">
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Reference Name + phone or email</label>
					<input type="text" pInputText formControlName="reference">
					<small *ngIf="myForm.get('reference').invalid && (myForm.get('reference').dirty || myForm.get('reference').touched)" class="p-invalid">This field is required</small>
				</div>
			</div>
		</div>

		<div class="p-col-12">
			<div class="p-grid">
				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Current Employer</label>
					<input type="text" pInputText formControlName="c1">
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Start Date</label>
					<p-calendar formControlName="c1dte" dateFormat="mm.dd.yy"></p-calendar>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid">
				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Previous Employer 1</label>
					<input type="text" pInputText formControlName="p1">
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Start Date</label>
					<p-calendar formControlName="p1startdte" dateFormat="mm.dd.yy"></p-calendar>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>End Date</label>
					<p-calendar formControlName="p1enddte" dateFormat="mm.dd.yy"></p-calendar>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="p-grid">
				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Previous Employer 2</label>
					<input type="text" pInputText formControlName="p2">
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>Start Date</label>
					<p-calendar formControlName="p2startdte" dateFormat="mm.dd.yy"></p-calendar>
				</div>

				<div class='p-col-12 p-lg-4 p-xl-4 p-md-12 p-sm-12'>
					<label>End Date</label>
					<p-calendar formControlName="p2enddte" dateFormat="mm.dd.yy"></p-calendar>
				</div>
			</div>
		</div>
		<div class="p-grid">
			<div class='p-col-12'>
				<button type="submit" pButton label="Submit" class="p-button p-button-success"></button>
			</div>
		</div>
	</div>
</form>