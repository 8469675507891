<div class="row">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>

		<p-table #dt1 *ngIf="!loading" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
			responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
			[rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['order_id']" [value]="orders" dataKey="id"
			responsiveLayout="scroll">
			<ng-template pTemplate="caption">
				<div class="flex">
					<button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
						(click)="clear(dt1)"></button>
					<span class="p-input-icon-left ml-auto">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
							placeholder="Search Order #" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th style="width: 3rem"></th>
					<th pSortableColumn="status">Order # <p-sortIcon field="status"></p-sortIcon></th>
					<th pSortableColumn="dtetme">Date <p-sortIcon field="dtetme"></p-sortIcon></th>
					<th pSortableColumn="cart.length">Items <p-sortIcon field="cart.length"></p-sortIcon></th>
					<th pSortableColumn="status">Tracking <p-sortIcon field="status"></p-sortIcon></th>
					<th pSortableColumn="status">Total <p-sortIcon field="status"></p-sortIcon></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-order let-ri="rowIndex" let-expanded="expanded">
				<tr>
					<td>
						<button type="button" pButton pRipple [pRowToggler]="order"
							class="p-button-text p-button-rounded p-button-plain"
							[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
					</td>
					<td>{{order.order_id}}</td>
					<td>
						{{order.dtetme | date : 'shortDate'}}
					</td>
					<td>
						{{order.cart.length}}
					</td>
					<td>{{order.status}}</td>
					<td>${{order.total}}</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="rowexpansion" let-details>
				<tr>
					<td colspan="3" style="vertical-align: top;">
						<app-order-shipping [details]="details.shipping_components"></app-order-shipping>
					</td>
					<td colspan="3">
						<div *ngFor="let item of details.cart">
							<app-product-details [details]="item"></app-product-details>
						</div>
					</td>
				</tr>
			</ng-template>

			<ng-template *ngIf="!orders.length" pTemplate="summary">
				No orders found.
			</ng-template>
		</p-table>
	</div>
</div>