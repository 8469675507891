import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductDetailsComponent } from './details.component';

import { TooltipModule } from 'primeng/tooltip';

@NgModule({
	declarations: [
		ProductDetailsComponent,
	],
	imports: [
		CommonModule,
		TooltipModule,
	],
	providers: [],
	exports: [ProductDetailsComponent]
})
export class ProductDetailsModule { }
