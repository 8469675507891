import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrderShippingComponent } from './shipping.component';

import { TooltipModule } from 'primeng/tooltip';

@NgModule({
	declarations: [
		OrderShippingComponent,
	],
	imports: [
		CommonModule,
		TooltipModule,
	],
	providers: [],
	exports: [OrderShippingComponent]
})
export class ShippingModule { }
