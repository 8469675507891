<app-admin-header></app-admin-header>
<section class="product-details section" style="margin-top: 100px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div class="card">
                    <p-treeTable *ngIf="!loading" [value]="pistols" [scrollable]="true" [tableStyle]="{'min-width':'50rem'}">
                        <ng-template pTemplate="header">
                            <tr>
                                <th *ngFor="let col of cols">{{col.header}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                            <tr [ttRow]="rowNode">
                                <td>
                                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                    {{ rowData.make }}
                                </td>
                                <td>{{ rowData.model }}</td>
                                <td>{{ rowData.instock }}</td>
                            </tr>
                        </ng-template>
                    </p-treeTable>
                </div>
            </div>
        </div>
    </div>
</section>