<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 col-md-6 col-sm-12 col-xs-12">
				<div class="products-photo">
					<!-- Tab panes -->
					<div class="tab-content">
						<div role="tabpanel" class="tab-pane active" id="img1">
							<img src="assets/images/little_friend/little_friend4.png" alt="" />
						</div>
						<div role="tabpanel" class="tab-pane" id="img4">
							<img src="assets/images/little_friend/little_friend1.png" alt="" />
						</div>
						<div role="tabpanel" class="tab-pane" id="img2">
							<img src="assets/images/little_friend/little_friend2.png" alt="" />
						</div>
						<div role="tabpanel" class="tab-pane" id="img3">
							<img src="assets/images/little_friend/little_friend3.png" alt="" />
						</div>
					</div>
					<!-- Nav tabs -->
					<ul class="nav nav-tabs" role="tablist">
						<li role="presentation" class="active">
							<a href="#img1" role="tab" data-bs-toggle="tab"><img
									src="assets/images/little_friend/little_friend4.png" alt="" /></a>
						</li>
						<li role="presentation" >
							<a href="#img4" role="tab" data-bs-toggle="tab"><img
									src="assets/images/little_friend/little_friend1.png" alt="" /></a>
						</li>
						<li role="presentation">
							<a href="#img2" role="tab" data-bs-toggle="tab"><img
									src="assets/images/little_friend/little_friend2.png" alt="" /></a>
						</li>
						<li role="presentation">
							<a href="#img3" role="tab" data-bs-toggle="tab"><img
									src="assets/images/little_friend/little_friend3.png" alt="" /></a>
						</li>
						
					</ul>
				</div>
			</div>
			<div class="col-lg-7 col-md-6 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Customize your little friend</h2>
					<p-accordion>
						<p-accordionTab header="Pistol" [selected]="true">
							<div class="row">
								<div class='col-lg-12'>
									<p-table [value]="holster.pistols" responsiveLayout="scroll">
										<ng-template pTemplate="header">
											<tr>
												<th>Make</th>
												<th>Model</th>
												<th>QTY</th>
												<th><button (click)="addPistol()"
														pTooltip="Add another holster with the same configuration"
														class='btn btn-info'><i class='pi pi-plus'></i></button></th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-pistol let-ri="rowIndex">
											<tr>
												<td>
													<p-dropdown [options]="manufacturer" [autoDisplayFirst]="false"
														[(ngModel)]="pistol.make" optionLabel="label" [showClear]="true"
														filter="true" appendTo="body"></p-dropdown>
												</td>
												<td>
													<p-dropdown *ngIf="pistol.make" [options]="pistol.make.models"
														[autoDisplayFirst]="false" [(ngModel)]="pistol.model"
														optionValue="label" optionLabel="label" [showClear]="true"
														filter="true" appendTo="body"></p-dropdown>
												</td>
												<td>
													<p-inputNumber [min]="1" [(ngModel)]="pistol.qty"
														class='qty'></p-inputNumber>
												</td>
												<td><button *ngIf="holster.pistols.length > 1"
														(click)="removePistol(ri)" class='btn btn-danger'
														pTooltip="Remove"><i class='pi pi-trash'></i></button></td>
											</tr>
										</ng-template>
										<ng-template *ngIf="getTotal()" pTemplate="summary">
											<div style='text-align: right;'>Subtotal: ${{getTotal()}}</div>
										</ng-template>
									</p-table>
								</div>
							</div>
						</p-accordionTab>
						<p-accordionTab [header]="'Color'">
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<b>Selected color</b>: {{holster.color}}
								</div>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<div *ngFor="let color of colors" [pTooltip]="color.label"
										(click)="holster.color = color.label"
										[ngStyle]="{'background-color': color.color}"
										style="cursor: pointer; display: block; width: 50px; height: 50px; float: left;">
										<i *ngIf="holster.color == color.label" class="pi pi-check-circle"
											style="margin-left: 12px; margin-top: 14px; font-size: 24px; color: green;"></i>
									</div>
								</div>
							</div>
						</p-accordionTab>

						<p-accordionTab [header]="'Lanyard'">
							<div class="col-lg-12" style="margin-top: 10px;">
								<p>The lanyard can consist of 1 base color and up to 3 colored strands or just 1 base color. Choose flag colors, cause colors, team colors, whatever you'd like!
								</p>
							</div>
							<div class="col-lg-12" style="margin-top: 10px;">
								<label style="float: left; display: block;">Select a Base Color</label> <span
									*ngIf='holster.base' style="margin-left: 10px;">{{holster.base.label}}</span>
								<app-color [selectedColor]="holster.base" (callback)="holster.base = $event"
									style="float: left; margin-left: 10px;"></app-color>
							</div>
							<div class="col-lg-12" style="margin-top: 10px;">
								<label style="float: left; display: block;">Strand 1 Color</label><span
									*ngIf='holster.color1' style="margin-left: 10px;">{{holster.color1.label}} <i (click)="removeColor(holster.color1)" pTooltip="Remove strand" class='pi pi-times'></i></span>
								<app-color [selectedColor]="holster.color1" (callback)="holster.color1 = $event"
									style="float: left; margin-left: 10px;"></app-color>
							</div>

							<div class="col-lg-12" style="margin-top: 10px;">
								<label style="float: left; display: block;">Strand 2 Color</label><span
									*ngIf='holster.color2' style="margin-left: 10px;">{{holster.color2.label}} <i (click)="removeColor(holster.color2)" pTooltip="Remove strand" class='pi pi-times'></i></span>
								<app-color [selectedColor]="holster.color2" (callback)="holster.color2 = $event"
									style="float: left; margin-left: 10px;"></app-color>
							</div>

							<div class="col-lg-12" style="margin-top: 10px;">
								<label style="float: left; display: block;">Strand 3 Color</label><span
									*ngIf='holster.color3' style="margin-left: 10px;">{{holster.color3.label}} <i (click)="removeColor(holster.color3)" pTooltip="Remove strand" class='pi pi-times'></i></span>
								<app-color [selectedColor]="holster.color3" (callback)="holster.color3 = $event"
									style="float: left; margin-left: 10px;"></app-color>
							</div>
						</p-accordionTab>
					</p-accordion>
					<!-- {{holster | json}} -->
					<div class="select-pro">
						<div class="buttons">
							<button role="button" (click)="addToCart()" class="btn1">Add to cart</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row section5">
			<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
				<p-panel header="How does it work?">
					<iframe style="width: 100%; height: 511px;" src="https://www.youtube.com/embed/DeEh_TTkBC0" frameborder="0"
						allowfullscreen></iframe>
				</p-panel>
			</div>
			<div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
				<p-panel header="The Details">
					<h3>What is this</h3>
					<p>This is Little Friend. This is a trigger guard holster for pistols small enough to conceal in
						your pocket, purse, or laptop bag. If your pistol fits in a pocket, Little Friend works. It
						covers just the trigger and is attached to your belt buckle via 550 paracord and a carabiner.
					</p>
					<h3>Materials</h3>
					<p>The Kydex is .08 inches (2.03mm) thick. Strong, durable. We use 550 Paracord which has a strength
						of 550lb. Unbraided, it's 10ft of cord that can be used in an emergency. The steel shackle can
						be removed with the twist of a pin and can handle a load of 485lb. Going out for the night?
						Remove the steel shackle and keep using Little Friend in an inside coat pocket.</p>
					<h3>How do you use it</h3>
					<p>Little friend is ambidextrous. Put little friend on your pistol, put your pistol in a pocket,
						attach the carabiner to a hook or belt loop. Little friend is directional so you can't screw
						this up. Need to draw? Hand in pocket, firm grip on your pistol and pull out. Little friend
						comes off and your ready to go.</p>
					<h3>Can this be used in a purse?</h3>
					<p>Absolutely! Purse, clutch, cross chest bag. Anywhere the carabiner has something to hook onto and
						the pistol fits in, you can use this. Each one is made as minimal as possible so it fits in as
						many pockets as possible.</p>
					<h3>Everything else</h3>
					<p>We're down for making something even more custom than what's listed above. Need a pistol not
						listed? Specific color? Bulk order? Message us and let's talk!</p>
				</p-panel>
			</div>
		</div>
	</div>
</section>