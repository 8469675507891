<section class="product-details section" style="margin-top: 100px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 col-md-6 col-sm-12 col-xs-12">
				<div class="products-photo">
					<!-- Tab panes -->
					<div class="tab-content">
						<div role="tabpanel" class="tab-pane active" id="img1">
							<img src="assets/images/bracelets/bracelet_1_1.png" alt="" />
						</div>
						<div role="tabpanel" class="tab-pane" id="img2">
							<img src="assets/images/bracelets/bracelet_1_2.png" alt="" />
						</div>
					</div>
					<!-- Nav tabs -->
					<ul class="nav nav-tabs" role="tablist">
						<li role="presentation" class="active">
							<a href="#img1" role="tab" data-bs-toggle="tab"><img
									src="assets/images/bracelets/bracelet_1_1.png" alt="" /></a>
						</li>
						<li role="presentation">
							<a href="#img2" role="tab" data-bs-toggle="tab"><img
									src="assets/images/bracelets/bracelet_1_2.png" alt="" /></a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-7 col-md-6 col-sm-12 col-xs-12">
				<div class="single-product-content">
					<h2>Customize your Bracelet</h2>
					<p-panel header="Select options">
						<div class="row">
							<div class="col-lg-12">
								<p>Please measure around your wrist, not to snug, but not to loose. If you can't take a
									measurement, adult mens is about 8 inches, adult womens is about 7 inches</p>
								<label>Select a size</label><br>
								<p-dropdown [options]="sizes" optionLabel="size" appendTo="body"
									[(ngModel)]="bracelet.size"></p-dropdown>
							</div>
							<div class="col-lg-12" style="margin-top: 10px;">
								<p>Bracelets consist of 1 base color and up to 3 colored strands as shown in the photos.
								</p>
							</div>
							<div class="col-lg-12" style="margin-top: 10px;">
								<label style="float: left; display: block;">Select a Base Color</label> <span
									*ngIf='bracelet.base' style="margin-left: 10px;">{{bracelet.base.label}}</span>
								<app-color [selectedColor]="bracelet.base" (callback)="bracelet.base = $event"
									style="float: left; margin-left: 10px;"></app-color>
							</div>
							<div class="col-lg-12" style="margin-top: 10px;">
								<label style="float: left; display: block;">Strand 1 Color</label><span
									*ngIf='bracelet.color1' style="margin-left: 10px;">{{bracelet.color1.label}}</span>
								<app-color [selectedColor]="bracelet.color1" (callback)="bracelet.color1 = $event"
									style="float: left; margin-left: 10px;"></app-color>
							</div>

							<div class="col-lg-12" style="margin-top: 10px;">
								<label style="float: left; display: block;">Strand 2 Color</label><span
									*ngIf='bracelet.color2' style="margin-left: 10px;">{{bracelet.color2.label}}</span>
								<app-color [selectedColor]="bracelet.color2" (callback)="bracelet.color2 = $event"
									style="float: left; margin-left: 10px;"></app-color>
							</div>

							<div class="col-lg-12" style="margin-top: 10px;">
								<label style="float: left; display: block;">Strand 3 Color</label><span
									*ngIf='bracelet.color3' style="margin-left: 10px;">{{bracelet.color3.label}}</span>
								<app-color [selectedColor]="bracelet.color3" (callback)="bracelet.color3 = $event"
									style="float: left; margin-left: 10px;"></app-color>
							</div>
						</div>
					</p-panel>

					<div class="row" style="margin-top: 10px;">
						<div class="col-lg-12" style="color: white;">
							<b>Total: </b> $29.95
						</div>
					</div>
					<div class="select-pro" style="margin-top: 10px;">
						<div class="buttons">
							<button role="button" (click)="addToCart()" class="btn1">Add to cart</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row section5">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<p-panel header="The Details">
					<h3>What is this</h3>
					<p>Unisex bracelet made of 550 Paracord with polished steel clasp. The bracelet is comprised of 1
						base color (the braid) and up to 3 strands down the middle. Choose your colors based on
						hertaige, team colors or cause colors. Up to 3 colors can be chosen at no additional cost. These
						are handmade and made to order.</p>
					<h3>How to choose the size</h3>
					<p>Measure around your wrist snug, but not tight. Size 6 works well on a teenager. Size 7 works well
						on a an average women. Size 8 works well on an average man. The Steel clasp has 3 positions to
						adjust tightness. When we make them, we make them to the middle position.</p>
					<h3>Materials</h3>
					<p> We use 550 Paracord which has a strength of 550lb. Unbraided, it's 8-10ft of cord that can be
						used in an emergency.</p>
					<h3>How do you use it</h3>
					<p>Wrap the bracelet around your wrist and insert the steel pin on the non-threaded side. Screw in
						pin until tight.</p>
				</p-panel>
			</div>
		</div>
	</div>
</section>