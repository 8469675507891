<div *ngIf="!loading">
	<div *ngIf="!address" style="margin-top: 20px;">
		<div class="row">
			<div class="col-lg-12">
				<label>Address</label><br>
				<input id="anyotheraddress" autocomplete="off" pInputText ngx-google-places-autocomplete
					[options]='options' (onAddressChange)="handleAddressChange($event)" #placesRef="ngx-places"
					style='width: 100%;' />
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<button *ngIf="editing == true" role="button" (click)="saveAddress()" class="btn1"
					style="float: right;">Save</button>
			</div>
		</div>
	</div>

	<div *ngIf="address" style="margin-top: 20px;">
		<p>Please verify the address below</p>
		<div class="row">
			<div class="col-lg-12">
				<label>Address 1</label>
				<input type="text" [(ngModel)]="address_components.address1" pInputText class="form-control">
			</div>
		</div>

		<div class="row">
			<div class="col-lg-12">
				<label>Address 2</label>
				<input type="text" [(ngModel)]="address_components.address2" pInputText class="form-control">
			</div>
		</div>

		<div class="row">
			<div class="col-lg-4">
				<label>City</label>
				<input type="text" [(ngModel)]="address_components.city" pInputText class="form-control">
			</div>
			<div class="col-lg-4">
				<label>State</label>
				<input type="text" [(ngModel)]="address_components.state" pInputText class="form-control">
			</div>
			<div class="col-lg-4">
				<label>Postal Code</label>
				<input type="text" [(ngModel)]="address_components.postal" pInputText class="form-control">
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<label>Country</label>
				<input type="text" [(ngModel)]="address_components.country" pInputText class="form-control">
			</div>
		</div>
	</div>

	<div class="row" style="margin-top: 20px;">
		<div class="col-lg-12">
			<div class="select-pro">
				<div class="buttons">
					<button role="button" (click)="saveAddress()" class="btn1" style="float: right;">{{cart ? "Next" :
						"Save"}}</button>
				</div>
			</div>
		</div>
	</div>
</div>