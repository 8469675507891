<app-admin-header></app-admin-header>

<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <section class="product-details section" style="margin-top: 100px;">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                            <div class="single-product-content">
                                <h2>Users Today</h2>
                            </div>
                            <p-table [paginator]="true" [rows]="10" responsiveLayout="scroll"
                                [rowsPerPageOptions]="[10,25,50]" [value]="users" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-user>
                                    <tr>
                                        <td>{{user.name}}</td>
                                        <td>{{user.email}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <section class="product-details section" style="margin-top: 100px;">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                            <div class="single-product-content">
                                <h2>Orders Today</h2>
                            </div>
                            <p-table [paginator]="true" [rows]="10" responsiveLayout="scroll"
                                [rowsPerPageOptions]="[10,25,50]" [value]="orders" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Email</th>
                                        <th>Name</th>
                                        <th>Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-user>
                                    <tr>
                                        <td>{{user.email}}</td>
                                        <td>{{user.name}}</td>
                                        <td>{{user.amount}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>