import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApplicationsService } from './application.service';
@Component({
	selector: 'app-admin-applications',
	templateUrl: './application.component.html',
})
export class AdminApplicationsComponent implements OnInit, OnDestroy {
	applications: any[] = [];
	loading: boolean = false;

	toggleOverlay: boolean = false;
	selectedApplication: any;
	constructor(
		private applicationsService: ApplicationsService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService
	) {

	}

	ngOnInit(): void {
		this.getData();
	}

	ngOnDestroy(): void {

	}

	getData() {
		this.loading = true;
		this.applicationsService.getApplications().subscribe(res => {
			console.log(res);
			this.applications = res;
			this.loading = false;
		})
	}

	deleteApplication(application) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to delete this application?',
			accept: () => {
				this.applicationsService.deleteApplication(application.id).then(res => {
					console.log(res);
					this.messageService.add({ severity: 'success', summary: 'System', detail: 'Delete Successful' });
					this.getData();
				})
			}, reject: () => {
				return false;
			}
		});
	}

	viewApplication(application) {
		this.selectedApplication = application;
		this.toggleOverlay = true;
	}
}
