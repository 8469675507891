import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as paracord from 'src/assets/json/paracord.json';

@Component({
    selector: 'app-color',
    templateUrl: './color.component.html',
})
export class ColorComponent implements OnInit {
    paracord: any[] = (paracord as any).default['colors'];
    @Input() selectedColor: any;
    @Output() callback = new EventEmitter();

    constructor(
    ) {

    }

    ngOnInit(): void {
        // this.selectedColor = this.paracord[0];

    }

    getRow(i) {
        let out = i % 5;
        if (out) { return null; } else { return "both"; }
    }

    setColor(color, op: OverlayPanel) {
        this.callback.emit(color);
        op.hide();
    }
}
