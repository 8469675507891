import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CartComponent } from './cart.component';

import { HoursService } from 'src/app/admin/hours/hours.service';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemService } from 'src/app/services/system.service';
import { MessageService } from 'primeng/api';
import { CartService } from './cart.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxStripeModule } from 'ngx-stripe';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProductDetailsModule } from 'src/app/sub/details/details.module';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AddressModule } from 'src/app/sub/address/address.module';
import { BoxDimensionCalculatorService } from 'src/app/services/dimensions.service';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { environment } from 'src/environments/environment';
import { MessagesModule } from 'primeng/messages';



const routes: Routes = [
	{ path: 'cart', component: CartComponent }
]

@NgModule({
	declarations: [
		CartComponent,
	],
	imports: [
		MenuModule,
		DropdownModule,
		AddressModule,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		TooltipModule,
		TableModule,
		ButtonModule,
		InputTextModule,
		AccordionModule,
		InputNumberModule,
		HttpClientModule,
		ProgressBarModule,
		InputMaskModule,
		CalendarModule,
		MessagesModule,
		SelectButtonModule,
		// NgxStripeModule.forRoot('pk_8ZpecgSOQegLQcYQyDe7VP8XNHbj9'),
		NgxStripeModule.forRoot(environment.production ? 'pk_live_40PJBvTy8Gd6uBDWVSbhAgXbrtNXZvMbsYPbyI9PeoTcfd8HIghpypPyOMsU6rbfz4oBzn6jNaL5f3qMM4ccxS9Ki00CguLLgJW' : 'pk_8ZpecgSOQegLQcYQyDe7VP8XNHbj9'),
		ProductDetailsModule,
		RouterModule.forChild(routes),

	],
	providers: [HoursService,
		SystemService,
		MessageService,
		CartService,
		HttpClient,
		BoxDimensionCalculatorService,
	],
	exports: [CartComponent]
})
export class CartModule { }

