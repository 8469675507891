import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SystemService } from 'src/app/services/system.service';
import * as pistols from 'src/assets/json/pistols.json';
import * as colors from 'src/assets/json/colors.json';
import * as lights from 'src/assets/json/lights.json';

@Component({
	selector: 'app-1911',
	templateUrl: './1911.component.html',
})
export class GripComponent implements OnInit {
	grips: any[] = [
		{ type: "grip", name: "Love & Hate", price: 80, outer_color: "", inner_color: "", images: [{ image: 'assets/images/270x400.png' }, { image: 'assets/images/270x400.png' }] },
		{ type: "grip", name: "Test 2", price: 80, outer_color: "", inner_color: "", images: [{ image: 'assets/images/270x400.png' }, { image: 'assets/images/270x400.png' }] },
		{ type: "grip", name: "Test 3", price: 80, outer_color: "", inner_color: "", images: [{ image: 'assets/images/270x400.png' }, { image: 'assets/images/270x400.png' }] },
		{ type: "grip", name: "Test 4", price: 80, outer_color: "", inner_color: "", images: [{ image: 'assets/images/270x400.png' }, { image: 'assets/images/270x400.png' }] },
	]
	
	colors: any[] = (colors as any).default['colors'];

	selectedGrip;

	constructor(
		private messageService: MessageService,
		private systemService: SystemService
	) {

	}

	ngOnInit(): void {

	}

	Customize(item) {
		this.selectedGrip = item;
		this.selectedGrip.qty = 1;
	}

	addToCart(grip) {
		let cart = [];
		if (localStorage.getItem('cart')) { cart = JSON.parse(localStorage.getItem('cart')) };

		cart.push(this.selectedGrip);
		console.log("Cart", cart);
		this.systemService.saveCartToStorage(cart);
		this.messageService.add({ key: "create", severity: 'success', summary: 'Cart', detail: 'Holster added!' });
		this.systemService.cartUpdated.next("");
	}
}
