
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    constructor(
        private db: AngularFirestore,
    ) {

    }
    getUsers() {
        return this.db.collection<any>('profile').valueChanges();
    }

    getOrders() {
        return this.db.collection<any>('orders').valueChanges();
    }


    getStartOfToday() {
        var d = new Date();
        d.setHours(0, 0, 0, 0);
        console.log(d);
        return d // ex. 1631246400
    }
}
